import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { AiInteractionType, LLM, LLMThreshold, RoadMateFile, SupportConversation, SupportMessage,
  SupportMessageReactions, UserSituation, error_messages,
  fileStoringWorkflows } from '@roadmate/roadmate-common';
import { FireFunctionsService, FireStoreService, ModalService, RMToasterService } from '@rm-services';
import { Observable, Subject } from 'rxjs';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IsoToFrenchDateTimePipe } from '@rm-modules/pipes/iso-to-french-date.pipe';
import { EmailToNamePipe } from './email-to-name.pipe'
import { SortArrayByTimePipe } from '@rm-modules/pipes';
import { FormsModule } from '@angular/forms';
import { UploadInputComponent } from '@rm-modules/upload-input/upload-input.component' 
import { 
  BadgeComponent, ButtonDirective, ColComponent,
  DropdownModule, 
  FormControlDirective, FormLabelDirective, InputGroupComponent, InputGroupTextDirective, 
  ProgressComponent, RowComponent, SpinnerModule, Tabs2Module, TextColorDirective 
} from '@coreui/angular';
import moment from 'moment';
import { UserSituationComponent } from '@rm-modules/user-situation/user-situation.component';
import { TransactionsViewerComponent } from '@rm-modules/transactions-viewer/transactions-viewer.component';
import { IconDirective } from '@coreui/icons-angular';
import { cilDoor, cilPaperPlane } from '@coreui/icons';
import { Editor, NgxEditorModule } from 'ngx-editor';
@Component({
  selector: 'rm-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
  standalone: true,
  imports: [
    NgClass, TranslateModule, NgIf, IsoToFrenchDateTimePipe, EmailToNamePipe, 
    SortArrayByTimePipe, AsyncPipe, FormsModule, UploadInputComponent, 
    ProgressComponent, TextColorDirective, UserSituationComponent, TransactionsViewerComponent,
    Tabs2Module, IconDirective, RowComponent, ColComponent, BadgeComponent, ButtonDirective,
    SpinnerModule, NgFor, InputGroupComponent, FormLabelDirective, InputGroupTextDirective,
    DropdownModule, FormControlDirective, NgxEditorModule
  ]
})
export class ConversationComponent  extends AbstractEntryComponent<boolean> implements OnInit {
  public agentRef: string;
  public companyRef: string;
  private email: string;
  public askingAi = false;
  public conversation: SupportConversation;
  public messages: Observable<SupportMessage[]>;
  public messageText: string = '';
  public fileType = fileStoringWorkflows.support;
  public currentTab: 'messages' | 'situation' | 'viewCardTransactions' = 'messages';
  public userSituation: UserSituation;
  public loading: boolean = false;
  public cardTransactionsSource = [];
  public cardTransactionsSettings: any;
  public editor: Editor;
  public get llms(): typeof LLM {
    return LLM;
  }
  override set data(value: any) {
    if (!value || !value.conversation?.ref) {
      throw error_messages.MISSING_PARAMETER;
    }
    this.conversation = value.conversation;
  }
  icons = {
    cilPaperPlane,
    cilDoor,
    // cilMag
  }
  public aiSuggestion = '';
  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.agentRef = this.conversation.agentRef;
    this.companyRef = this.conversation.companyRef;
    this.email = this.conversation.email;
    if (!this.conversation || !this.conversation.ref || !this.agentRef || !this.companyRef || !this.email) {
      throw error_messages.MISSING_PARAMETER;
    }
    this.getAllMessages();
  }

  public setTab(tab: 'messages' | 'situation' | 'viewCardTransactions') {
    this.currentTab = tab;
    if (!this.userSituation) {
      this.getStatus();
    }
  }

  public async suggestResponse() {
    this.askingAi = true;
    try {
      const response = await this.ff.askai({
        context: 'support_conversation',
        uid: this.conversation.uid,
        agentRef: this.conversation.agentRef,
        companyRef: this.conversation.companyRef,
        interactionType: AiInteractionType.chat_completion,
        model: '',
        llm: LLM.gemini,
        prePrompt: '',
        prompt: this.messageText,
        threshold: LLMThreshold.DEFAULT
      });
      if (!response.error && response.result) {
        this.aiSuggestion = response.result;
      } else {
        this.toast.showGenericError(`gemini n'a pas pu proposer une suite à cette conversation. ${response.result}`);
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
      this.askingAi = false;
    }
  }

  public async askAi(llm: LLM) {
    if (this.aiSuggestion) {
      this.toast.showGenericError('Vous devez d\'abord accepter ou rejeter la suggestion précédente.');
      return;
    }
    if (!this.messageText) {
      this.toast.showGenericError('Vous devez d\'abord écrire un message à améliorer.');
      return;
    }
    this.askingAi = true;
    try {
      const response = await this.ff.askai({
        context: 'support_conversation',
        uid: this.conversation.uid,
        agentRef: this.conversation.agentRef,
        companyRef: this.conversation.companyRef,
        interactionType: AiInteractionType.text_correction_in_html,
        model: '',
        llm,
        prePrompt: '',
        prompt: this.messageText,
        threshold: LLMThreshold.DEFAULT
      });
      if (!response.error && response.result) {
        this.aiSuggestion = response.result;
      } else {
        this.toast.showGenericError(`${llm} n'a pas pu répondre à votre demande. ${response.result}`);
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
      this.askingAi = false;
    }
  }

  public async acceptAiSuggestion() {
    if (!this.aiSuggestion) {
      return;
    }
    await this.doSendThisMessage(this.aiSuggestion);
    this.aiSuggestion = '';
    this.messageText = '';
  }

  public editSuggestion() {
    if (!this.aiSuggestion) {
      return;
    }
    this.messageText = this.aiSuggestion
    this.aiSuggestion = '';
  }

  private async getStatus() {
    this.loading = true;
    try {
      const {agentRef, companyRef, email} = this.conversation;
      const response = await this.ff.adminGetUserStatus(agentRef, companyRef, email);
      if (response.result) {
        this.userSituation = response.result;
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private getAllMessages() {
    if (!this.fs.currentAppUser.uid || !this.conversation.ref) {
      return;
    }
    const subject = new Subject<SupportMessage[]>();
    this.messages = subject.asObservable();
    this.unsubscribe = this.fs.getMessagesFromConversation(this.conversation.uid, this.conversation.ref, subject);
  }

  public async createMessageFromFile(data: {doc: RoadMateFile, fieldName: string}) {
    if (!data.doc?.url || !this.fs.currentAppUser.uid || !this.conversation.ref) {
      return;
    }
    const message: SupportMessage = {
      uid: this.fs.currentAppUser.uid,
      conversationRef: this.conversation.ref,
      message: '',
      file: data.doc,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      sender: 'agent',
      reaction: SupportMessageReactions.support_no_reaction,
      email: this.fs.currentAppUser.email
    };
    await this.fs.createSupportMessage(
      this.conversation.uid,
      this.conversation.ref,
      message
    );
  }

  public async sendMessage() {
    if (!this.messageText) {
      return;
    }
    await this.doSendThisMessage(this.messageText);
    this.messageText = '';
  }

  private async doSendThisMessage(text: string) {
    if (!this.fs.currentAppUser.uid || !this.conversation.ref) {
      return;
    }
    const message: SupportMessage = {
      uid: this.fs.currentAppUser.uid,
      conversationRef: this.conversation.ref,
      message: text,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      sender: 'agent',
      reaction: SupportMessageReactions.support_no_reaction,
      email: this.fs.currentAppUser.email
    };
    await this.fs.createSupportMessage(
      this.conversation.uid,
      this.conversation.ref,
      message
    );
    await this.fs.updateConversation(
      this.conversation.uid,
      this.conversation.ref,
      {
        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        lastSender: 'agent',
        lastMessage: text,
        readByUser: false,
      }
    );
  }

  public async selectMessage(message: SupportMessage) {
    if (message.sender === 'agent') {
      return;
    }
    if (message.file?.url) {
      window.open(message.file.url, '_blank');
    }
    if (message.isRead || !message.ref) {
      return;
    }
    if (!this.fs.currentAppUser.uid || !this.conversation.ref) {
      return;
    }
    await this.fs.setMessageAsRead(
      this.conversation.uid,
      this.conversation.ref,
      message.ref
    );
  }

  public async closeConversation() {
    if (!this.fs.currentAppUser.uid || !this.conversation.ref) {
      return;
    }
    await this.fs.updateConversation(
      this.conversation.uid,
      this.conversation.ref,
      {
        status: 'resolved',
        updatedAt: new Date().toISOString()
      }
    );
    this.close(false);
  }

  public override ngOnDestroy(): void {
    this.editor.destroy();
  }
}
