import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BeneficiaryGroup, DropDownListOption, FormDefinition, GroupedDDL, RoadMateOrders, Treezor } from '@roadmate/roadmate-common';
import { FireFunctionsService, FireStoreService, ModalService, RMToasterService } from '@rm-services';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { ProgressComponent } from '@coreui/angular';
import { RMMultiSelectModule } from '@rm-modules/multi-select/multi-select.module';
import { AbstractEntryComponent } from '@rm-modals/abstract.entry-component';


@Component({
  selector: 'rm-groups-component',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, NgIf, ProgressComponent, RMMultiSelectModule, NgFor
  ]
})
export class GroupsComponent extends AbstractEntryComponent<void> implements OnInit, OnDestroy {
  public ordersLoaded = false;
  public groups: GroupedDDL[];
  public currentOrder: GroupedDDL;
  public groupWithUsers: BeneficiaryGroup[] = [];
  public currentFilter: GroupedDDL[] = [];
  public orders: RoadMateOrders[] = [];
  public ordersDDL: DropDownListOption[] = [];
  public groupDefinition: FormDefinition;
  public users: DropDownListOption[] = [];
  public allUsers: Treezor.AppUser[] = [];
  public groupSettings: any;
  public dirtyIndexes: {index: number, groups: GroupedDDL[]}[] = [];
  public savingIndex = -1;
  public selectedGroup: DropDownListOption;
  public groupRef: string;
  @Output() groupsLengthEmitter = new EventEmitter<number>();
  override set data(value: string) {
    if (!value) {
      return;
    }
    this.groupRef = value;
  }

  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    private modal: ModalService,modalService: ModalService,
  ) {
    super(modalService);
  }

  public ngOnInit(): void {
    this.getGroups();
  }

  private async getGroups() {
    try {
      this.ordersLoaded = false;
      const {agentRef} = this.fs.currentAppUser;
      const ref = this.fs.currentCompany.ref;
      if (!ref) {
        return;
      }
      this.orders = await this.fs.getClientOrders(agentRef, ref);
      this.groupWithUsers = await this.fs.getUsersInGroups(agentRef, ref);
      this.ordersDDL = this.orders.map(el => {
        return {
          value: el.ref ?? '',
          label: el.product.name
        }
      });
      this.groups = this.orders.map(el => {
        return {
          value: el.ref ?? '',
          label: el.product.name,
          ddl: el.beneficiaryGroup ?
          el.beneficiaryGroup.map(item => {
            return {
              value: item.value,
              label: item.label
            }
          }) : []
        }
      });
      this.groups.some(el => el.ddl.some(g => {
        if (g.value === this.groupRef) {
          this.updateUserGroups([
            {
              value: el.value,
              label: el.label,
              ddl: [
                g
              ]
            }
          ]);
          return true;
        }
        return false;
      }));
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError();
    } finally {
      this.ordersLoaded = true;
    }
  }


  public updateUserGroups(items: GroupedDDL[]) {
    const [order] = items;
    if (!order) {
      this.users = [];
      return;
    }
    this.currentOrder = order;
    const [group] = order.ddl;
    if (!group) {
      this.users = [];
      return;
    }
    this.selectedGroup = group;
    this.users = this.groupWithUsers.find(el => el.ref === group.value)?.usersDdl ?? [];
  }

  public makeUserDirty(groups: any, index: number) {
    const el = this.dirtyIndexes.find(user => user.index === index);
    if (el) {
      el.groups = groups;
      return;
    }
    this.dirtyIndexes.push({index, groups});
  }

}
