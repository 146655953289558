import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import moment from 'moment-timezone';
import { error_messages, RoadMateTransfer, RoadMateTransportFrequence } from '@roadmate/roadmate-common';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { VarDirective, CurrencyPipe } from '@rm-modules/pipes';
import { ModalService } from '@rm-services';

@Component({
  selector: 'rm-confirm-transfer',
  templateUrl: './confirm-transfer.component.html',
  styleUrls: ['./confirm-transfer.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, CurrencyPipe, NgIf, VarDirective, NgFor
  ]
})
export class ConfirmTransferComponent extends AbstractEntryComponent<RoadMateTransfer | null> implements OnInit {
  public transfer: RoadMateTransfer;
  public date = moment().local().format('DD/MM/YYYY');
  public showUsers = true;
  public isAutomatic = false;
  public showAutomaticPayoutsPanel = true;
  public viewOnly = false;
  override set data(value: any) {
    if (!value || !value.transfer) {
      throw error_messages.MISSING_PARAMETER;
    }
    this.transfer = value.transfer;
    this.viewOnly = !!value.viewOnly;
    this.transfer.nextPayout = '';
    this.transfer.repeat = false;
    this.transfer.repeatDay = 1;
    this.transfer.monthFrequency = RoadMateTransportFrequence.monthly;
  }
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.showUsers = this.transfer.usersCount < 5;
    const currentDate = parseInt((new Date().toISOString()).substr(8,2), 10);
    if (currentDate > 27) {
      this.transfer.repeatDay = 1;
    } else {
      this.transfer.repeatDay = currentDate;
    }
    const numberOfMonths = moment(this.transfer.endDate).diff(moment(this.transfer.startDate), 'months') + 1;
    if (Object.values(RoadMateTransportFrequence).indexOf(numberOfMonths) > -1) {
      this.transfer.monthFrequency = numberOfMonths;
    } else {
      this.transfer.monthFrequency = RoadMateTransportFrequence.annually;
    }
    // this.setNextPayout();
  }

  // private setNextPayout() {
  //   const nextPayout = moment(this.transfer.startDate, 'YYYY-MM-DD').add(this.transfer.monthFrequency, 'months').format('DD/MM/YYYY');
  //   const repeatDay = `${this.transfer.repeatDay}`.padStart(2, '0');
  //   this.transfer.nextPayout = `${repeatDay}/${nextPayout.substring(3, 10)}`;
  // }

  // updateMonthFrequency(el) {
  //   console.log(el);
  //   setTimeout(() => {
  //     this.setNextPayout();
  //   }, 500);
  // }

  // updatePayoutDay(el) {
  //   console.log(el);
  //   setTimeout(() => {
  //     this.setNextPayout();
  //   }, 500);
  // }

  toggleUsers () {
    this.showUsers = !this.showUsers;
  }

}
