<!-- GENERAL DETAILS -->
 <c-row>
  <c-col sm="12" md="3" *ngIf="roles">
    <div class="card mb-3 fixed-card" 
      [ngClass]="{'border-danger': roles.suspended || !roles.employee, 'border-success': !roles.suspended && roles.employee}">
      <div class="card-header">
        <h5 class="bold" [ngClass]="{'text-danger': roles.suspended || !roles.employee}">
          User
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngIf="roles.suspended" [ngClass]="{'red': roles.suspended}"><strong>Suspendu:</strong> {{roles.suspended ? 'Oui' : 'Non'}}</li>
          <li class="list-group-item" *ngIf="roles.employee" [ngClass]="{'bold': roles.employee}"><strong>Employee:</strong> {{roles.employee ? 'Oui': 'Non'}}</li>
          <li class="list-group-item" *ngIf="roles.admin" [ngClass]="{'bold': roles.admin}"><strong>Admin:</strong> {{roles.admin ? 'Oui': 'Non'}}</li>
          <li class="list-group-item" *ngIf="roles.accountmanager" [ngClass]="{'bold': roles.accountmanager}"><strong>Account Manager:</strong> {{roles.accountmanager  ? 'Oui': 'Non'}}</li>
          <li class="list-group-item" *ngIf="company"><strong>Société : </strong>{{company.legalName}}, {{company.address1}}, {{company.postcode}}, {{company.city}}</li>
          <li class="list-group-item" *ngIf="appUser"><strong>Adresse de résidence :</strong> {{appUser.address1}}, {{appUser.postcode}}, {{appUser.city}}</li>
          <li class="list-group-item" *ngIf="appUser?.distanceFromWork"><strong>Domicile - Travail :</strong> {{appUser.distanceFromWork}}Km</li>
          <li class="list-group-item" *ngIf="appUser?.mobile"><strong>Portable :</strong> 
            <a [href]="'tel:'+appUser.mobile">
              {{appUser.mobile}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </c-col>
  <c-col sm="12" md="3" *ngIf="card">
    <div 
      class="card fixed-card mb-3"
      [ngClass]="{'border-danger': !isCardOk, 'border-success': isCardOk}"
    >
      <div class="card-header">
        <h5 class="bold" [ngClass]="{'text-danger': !isCardOk, 'text-success': isCardOk}">
          Carte
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">Card Number: XXXXXXXXXXXX{{card.cardNumber}}</li>
          <li class="list-group-item">ExpiryDate: {{card.expiryDate}}</li>
          <li class="list-group-item">Status: {{card.lockStatus}}</li>
          <li class="list-group-item" [ngClass]="{'red': !card.hasSetupPassword}">PWD Set up: {{card.hasSetupPassword ? 'Yes' : 'No'}}</li>
          <li class="list-group-item" [ngClass]="{'red': !card.pinStatusValid}">Pin Status: {{card.pinStatusValid ? 'OK' : 'NOK'}}</li>
          <li class="list-group-item">Tracking Code: 
            <a [href]="'https://www.laposte.fr/outils/suivre-vos-envois?code='+card.shippingTrackingCode" target="_blank" *ngIf="card.shippingTrackingCode">
              {{card.shippingTrackingCode}}
            </a>
          </li>
          <li class="list-group-item">Tracking Email sent: {{card.trackingCodeSent ? 'Oui': 'Non'}}</li>
          <li class="list-group-item" *ngIf="card.createdAt">Carte Créée le : {{card.createdAt | rm_date}}</li>
        </ul>
      </div>
    </div>
  </c-col>
  <c-col sm="12" md="3" *ngIf="wallet">
    <div
    class="card fixed-card mb-3"
    [ngClass]="{'border-danger': !isWalletOk, 'border-success': isWalletOk}"
  >
    <div class="card-header">
      <h5 class="bold" [ngClass]="{'text-danger': !isWalletOk, 'text-success': isWalletOk}">
        Wallet
      </h5>
    </div>
    <div class="card-body">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" [ngClass]="{'red': wallet.authorizedBalance < 0}">AuthorizedBalance: {{wallet.authorizedBalance ? wallet.authorizedBalance : 0}}€</li>
        <li class="list-group-item" [ngClass]="{'red': wallet.sold < 0}">Solde: {{wallet.sold ? wallet.sold: 0}}€</li>
        <li class="list-group-item" [ngClass]="{'red': wallet.status === 'CANCELED'}">Status: {{wallet.status | translate}}</li>
        <li class="list-group-item" *ngIf="walletAndExpLineMissAligned">
          <button cButton color="primary" size="sm" (click)="alignWalletAndExpLine()">
            Aligner le solde
          </button>
        </li>
        <li class="list-group-item" *ngIf="walletAndExpLineMissAligned">
          Cette action permet de mettre à jour le solde du wallet et forcer l'EXPL sur l'état du wallet
        </li>
      </ul>
    </div>
  </div>
  </c-col>
  <c-col sm="12" md="3">
    <div 
    class="card fixed-card mb-3"
    [ngClass]="{'border-danger': !isExpenseLineOk, 'border-success': isExpenseLineOk}"
  >
    <div class="card-header">
      <h5 class="bold" [ngClass]="{'text-danger': !isExpenseLineOk, 'text-success': isExpenseLineOk}">
        Expense line
      </h5>
    </div>
    <div class="card-body">
      <ul class="list-group list-group-flush" *ngIf="exp; else noExp">
        <li class="list-group-item" [ngClass]="{'red': exp.authorizedBalance < 0}">Authorized: {{exp.authorizedBalance ? exp.authorizedBalance : 0}}€</li>
        <li class="list-group-item" [ngClass]="{'red': exp.remainingBalance < 0}">Solde: {{exp.remainingBalance ? exp.remainingBalance : 0}}€</li>
        <li class="list-group-item">
          Status: <rm-expenseline-status [status]="exp.status"></rm-expenseline-status>
          <button cButton color="primary" size="sm" *ngIf="exp.status === expStatus.Available" (click)="makeExpenseLineActive()">
            Aligner le solde
          </button>
        </li>
        <li class="list-group-item" >Enveloppe: {{exp.envelope ? exp.envelope : 0}}€ </li>
      </ul>
      <ng-template #noExp>
        <ul class="list-group list-group-flush">
          <li class="list-group-item red">
            La ligne de dépense associée à cette demande n'est pas/plus disponible
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
  </c-col>
 </c-row>

<!-- REQUESTS LIST -->
<div class="row detached" *ngIf="requests?.length">
  <div class="col-12">
    <div class="card mb-3">
      <div class="card-header">
        <h5 
          class="bold rm-clickable"
          #rfRequests="var"
          [var]="true"
          (click)="rfRequests.var = !rfRequests.var"
        >
          {{'previous_requests' | translate}} <span *ngIf="appUser?.email">({{appUser.email}})</span>
          <i class="cil-chevron-circle-down-alt" *ngIf="!rfRequests.var"></i>
          <i class="cil-chevron-circle-up-alt" *ngIf="rfRequests.var"></i>
        </h5>
      </div>
      <div class="card-body" *ngIf="!rfRequests.var" style="overflow-x: scroll;">
        <ng2-smart-table
          *ngIf="requests?.length && requestsSettings"
          [settings]="requestsSettings"
          [source]="requests"
        >
        </ng2-smart-table>
      </div>
    </div>
  </div>
</div>
<!-- IKV LIST -->
<div class="row detached" *ngIf="situation.ikvRequests?.length">
  <div class="col-12">
    <div class="card mb-3">
      <div 
        class="card-header rm-clickable"
        #ikvRequests="var"
        [var]="true"
        (click)="ikvRequests.var = !ikvRequests.var"
      >
        <h5 class="bold">
          {{'previous_ikv_requests' | translate}}  <span *ngIf="appUser">({{appUser.email}})</span>
          <i class="cil-chevron-circle-down-alt" *ngIf="!ikvRequests.var"></i>
          <i class="cil-chevron-circle-up-alt" *ngIf="ikvRequests.var"></i>
        </h5>
      </div>
      <div class="card-body" *ngIf="!ikvRequests.var" style="overflow-x: scroll;">
        <ng2-smart-table
          *ngIf="situation?.ikvRequests?.length && ikvRequestsSettings"
          [settings]="ikvRequestsSettings"
          [source]="situation.ikvRequests"
        >
        </ng2-smart-table>
      </div>
    </div>
  </div>
</div>
<!-- MOBILITY ACCOUNT PARAMETERS -->
 <c-row>
  <c-col sm="12" md="3" *ngIf="situation?.mobilityAccount">
    <div 
      class="card fixed-card mb-3"
      [ngClass]="{'border-danger': !isMobilityAccountOk, 'border-success': isMobilityAccountOk}"
    >
      <div class="card-header">
        <h5 class="bold">
          Mobility Counters
        </h5>
      </div>
      <div class="card-body">
        @if(situation.mobilityAccount) {
          <ul class="list-group list-group-flush">
            <li class="list-group-item" [ngClass]="{'red': (situation.mobilityAccount.PT) > 300}">Prime Transport: {{situation.mobilityAccount.PT?situation.mobilityAccount.PT:0 }}€ (max = 400€)</li>
            <li class="list-group-item">FMD: {{situation.mobilityAccount.FMD ? situation.mobilityAccount.FMD: 0 }}€  (max = 700€)</li>
            <li class="list-group-item">RTT: {{situation.mobilityAccount.RTT ? situation.mobilityAccount.RTT : 0 }}€</li>
            <li class="list-group-item bold">PMD: {{situation.mobilityAccount.PMD ? situation.mobilityAccount.PMD : 0 }}€ (max = 800€)</li>
            <li class="list-group-item">CM: {{situation.mobilityAccount.CM ? situation.mobilityAccount.CM : 0 }}€</li>
            <li class="list-group-item">DP{{situation.mobilityAccount.DP ? situation.mobilityAccount.DP : 0 }}€</li>
          </ul>
        }
      </div>
    </div>
  </c-col>

  <c-col sm="12" md="3" *ngIf="currentRequest">
    <div class="card fixed-card mb-3"
      [ngClass]="{'border-danger': !exp || currentRequest.amount > exp.authorizedBalance}">
      <div class="card-header">
        <h5 class="bold">
          Recap de la demande
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngIf="currentRequest.invoices">Montant payé: {{currentRequest.invoices.amount}}€</li>
          <li class="list-group-item" [ngClass]="{'red': !exp || currentRequest.amount > exp.authorizedBalance}">Montant du remb: {{currentRequest.amount }}€</li>
          <li class="list-group-item" [ngClass]="{'red': !exp || currentRequest.amount > exp.authorizedBalance}">Solde de l'EL: <span *ngIf="exp">{{exp.authorizedBalance }}€</span></li>
          <li class="list-group-item">Type: 
            <span class="badge text-bg-primary">
              {{currentRequest.type | translate}}
            </span>
          </li>
          <li class="list-group-item" *ngIf="currentRequest.type === 'REFUND_TRANSPORT_TITLE'">
            Mois: {{currentRequest.month | rm_month_zero_index:currentRequest.year}}
          </li>
          <li class="list-group-item">Justificatifs: 
            <ng-container *ngIf="currentRequest.invoices?.files?.length">  
              <a 
                *ngFor="let item of currentRequest.invoices.files; let i = index"
                [href]="item.url" target="_blank"
              >
                {{item.name | truncate:15:false:true}}
              </a>
            </ng-container>
            <ng-container *ngIf="currentRequest.attestation?.url">
              <a [href]="currentRequest.attestation?.url ?? '/'" target="_blank">
                Attestation
              </a>
            </ng-container>
          </li>
          <li class="list-group-item" [ngClass]="{'bold': !!(currentRequest.requestAutomation)}">Auto: {{currentRequest.requestAutomation ? 'Oui': 'Non'}}</li>
          <li class="list-group-item" [ngClass]="{'bold': !!currentRequest.requestInstallment}">Fractionnée: {{currentRequest.requestInstallment ? 'Oui': 'Non'}}</li>
          <li *ngIf="currentRequest.requestInstallment" class="list-group-item" [ngClass]="{'bold': currentRequest.requestInstallment}">
            Montant de la fraction: {{currentRequest.installmentAmount ? currentRequest.installmentAmount : currentRequest.amount}}€
          </li>
        </ul>
      </div>
    </div>
  </c-col>
  <c-col sm="12" md="6"  *ngIf="autoRefunds.length || installmentRefuds.length">
    <div class="card fixed-card mb-3"
      [ngClass]="{'border-danger': isThereAValidTransportAutoRefund, 'border-success': !isThereAValidTransportAutoRefund}">
      <div class="card-header">
        <h5 class="bold">
          Les demandes Auto déjà enregistrées
        </h5>
      </div>
      <div class="card-body">
        <ng2-smart-table
            *ngIf="autoRefundSetting && autoRefunds.length"
            [settings]="autoRefundSetting"
            [source]="autoRefunds"
          >
        </ng2-smart-table>
        <div *ngIf="installmentRefuds.length">
          InstallmentRefuds
        </div>
        <ng2-smart-table
            *ngIf="autoRefundSetting && installmentRefuds.length"
            [settings]="autoRefundSetting"
            [source]="installmentRefuds"
        >
        </ng2-smart-table>
        <p
          *ngIf="!autoRefunds.length && !installmentRefuds.length"
        >
          Aucune demande automatique enregistrée pour le moment
        </p>
      </div>
    </div>
  </c-col>
</c-row>

<!-- INSTALLMENT OR AUTO REFUND FORM -->
<c-row>
  <c-col sm="12" md="6" *ngIf="currentRequest?.requestAutomation">
    <div 
    class="card no-height-limit mb-3"
    [ngClass]="{'border-success': automationOptAttached}"
  >
    <div class="card-header">
      <h5 class="bold">
        Paramètres de l'automatisations
      </h5>
    </div>
    <div class="card-body">
      <rm-reactive-forms
        *ngIf="autoRefundDef && autoRefund"
        [objectDefinition]="autoRefundDef"
        [objectToUpdate]="autoRefund"
        (saveEmitter)="saveAutomationObject($event)"
      >
      </rm-reactive-forms>
      <div *ngIf="automationOptAttached">
        Paramètres de l'automatisations sauvegardées !
      </div>
    </div>
  </div>
  </c-col>
  <c-col sm="12" md="6" *ngIf="currentRequest?.requestInstallment">
    <div 
      class="card no-height-limit mb-3"
      [ngClass]="{'border-success': installementOptAttached}"
    >
      <div class="card-header">
        <h5 class="bold">
          Paramètres du Remboursement fractionné
        </h5>
      </div>
      <div class="card-body">
        <rm-reactive-forms
          *ngIf="autoRefundDef && installementRefundObject"
          [objectDefinition]="autoRefundDef"
          [objectToUpdate]="installementRefundObject"
          (saveEmitter)="saveInstallementObject($event)"
        >
        </rm-reactive-forms>
        <div *ngIf="installementOptAttached">
          Paramètres du remboursement fractionné sauvegardées !
        </div>
      </div>
    </div>
  </c-col>
</c-row>

<div class="row detached" *ngIf="currentRequest">
  <div class="col-12">
    <div class="form-group" >
      <c-form-check sizing="xl" switch>
        <input cFormCheckInput type="checkbox" [(ngModel)]="byPassCard" />
        <label cFormCheckLabel>{{'bypass_card_check'|translate}}</label>
      </c-form-check>
    </div>
  </div>
</div>
<div class="row detached" *ngIf="currentRequest && exp">
  <div class="col-12" *ngIf="detailsOfCompany">
    <h5>
      {{'details_of_company' | translate}}
    </h5>
    <p [innerHTML]="detailsOfCompany"></p>
  </div>
  <div class="col-12" *ngIf="generalCompanyInformation || generalAgentInformation">
    <p [innerHTML]="generalAgentInformation"></p>
    <p [innerHTML]="generalCompanyInformation"></p>
  </div>
  <div class="col-12 red" *ngIf="currentRequest.status !== 'PENDING'">
    <i class="cil-warning" style="margin-right: 10px;"></i>
    Cette demande a déjà été traitée : <span class="badge text-bg-primary">{{currentRequest.status | translate}}</span>
  </div>
  <div class="col-12 red" *ngIf="!exp">
    <i class="cil-warning" style="margin-right: 10px;"></i>
    La ligne de dépense rattachée à cette demande n'est plus disponible
  </div>
  <div class="col-12 red" *ngIf="exp && currentRequest.amount > exp.authorizedBalance">
    <i class="cil-warning" style="margin-right: 10px;"></i>
    Le montant du remboursement excède le budget restant.
  </div>
  <div class="col-12 red" *ngIf="!currentRequest.merchantRef">
    <i class="cil-warning" style="margin-right: 10px;"></i>
    Le marchant n'est renseigné
  </div>
  <div class="col-12 red" *ngIf="isOverPTLimit">
    <i class="cil-warning" style="margin-right: 10px;"></i>
    Le montant du remboursement fera dépasser la limite 400€ sur la prime transport.<br>
    Le montant max restant sur la prime transport est de {{remainingAllowancePT}}€. Veuillez réduire le montant du remboursement.
  </div>
  <div class="col-4">
    <button
      class="btn btn-primary"
      type="button"
      (click)="validateRequest()"
      [disabled]="validating || currentRequest.amount > exp.authorizedBalance || currentRequest.status !== 'PENDING' || isOverPTLimit || !currentRequest.merchantRef"
    >
      <span *ngIf="validating" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      {{'validate_request' | translate}}
    </button>
    <label class="containter-center-left" style="margin-left: 10px;">
      Ou
    </label>
  </div>
  <div class="col-8 containter-center-left"></div>
  <div class="col-sm-12">
    <div class="form-group" *ngIf="currentRequest">
      <label for="name">{{'rejection_decription' | translate}}</label>
      <textarea
        class="form-control"
        rows="2"
        [(ngModel)]="currentRequest.description"
      ></textarea>
    </div>
  </div>
  <div class="col-4">
    <button
      class="btn btn-primary"
      type="button"
      (click)="rejectRequest()"
      [disabled]="
      !currentRequest.description ||
      validating ||
      currentRequest.status !== 'PENDING'"
    >
      <span *ngIf="validating" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      {{'reject_request' | translate}}
    </button>
  </div>
</div>