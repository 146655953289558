import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { RoadMatePayoutRequest } from '@roadmate/roadmate-common';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe } from '@rm-modules/pipes';
import { ModalService } from '@rm-services';

@Component({
  selector: 'rm-confirm-payout',
  templateUrl: './confirm-payout.component.html',
  standalone: true, 
  imports: [
    TranslateModule, CurrencyPipe
  ]
})
export class ConfirmPayoutComponent extends AbstractEntryComponent<any> implements OnInit {
  public transfer: Partial<RoadMatePayoutRequest>;
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.transfer = value;
  }
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void { }

}
