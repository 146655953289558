<c-modal
  [keyboard]="false"
  [transition]="true"
  [visible]="true"
  scrollable
  alignment="top"
  backdrop="static"
  [fullscreen]="size === 'large'"
>
  <c-modal-header>
    <h5 cModalTitle>
      {{modalTitle | translate}} <span class="bolder" *ngIf="headerSuffix">{{headerSuffix}}</span>
      <span class="rm-clickable" (click)="copyValue(headerSuffix)" *ngIf="headerSuffix">
        <svg [cIcon]="icons.cilCopy" size="lg" title="copier"></svg>
      </span>
    </h5>
    <button cButtonClose (click)="close(false)"></button>
  </c-modal-header>
  <c-modal-body>
    <c-progress variant="striped" *ngIf="!dynamicComponent" color="primary" class="mb-3" />
    @if(dynamicComponent) {
      <ng-container *ngComponentOutlet="dynamicComponent; inputs: {data, id: modalId};" (closeModal)="close(false)" />
    }
  </c-modal-body>
  <c-modal-footer  *ngIf="!dismissFooter">
    <!-- <button cButton color="secondary">Close</button>
    <button cButton color="primary">Save changes</button> -->
    <button cButton color="secondary" (click)="close(false)">
      {{'cancel' | translate}}
    </button>
    <button *ngIf="actionlbl" color="primary"  (click)="close(true)" cButton>
      <span>
        {{actionlbl | translate}}
      </span>
    </button>
  </c-modal-footer>
</c-modal>


