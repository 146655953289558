<div class="rm-column" *ngIf="user">
  <h5>{{'add_new_user_subtitle' | translate}}</h5>
  <rm-grouped-multi-select
    *ngIf="products && products.length"
    [groups]="products"
    [placeholder]="'select_one_or_more_products'"
    [selectedItems]="selectedGroups"
    (changeEmitter)="selectionChanged($event)"
  >
  </rm-grouped-multi-select>

  <button
    [disabled]="!selectedGroups || !selectedGroups.length || loading"
    class="btn btn-primary detached"
    type="button"
    style="margin-top: 100px;"
    (click)="doSaveUser()"
  >
    {{'create' | translate}}
  </button>
</div>
<rm-reactive-forms
  *ngIf="appUserDef"
  [ngClass]="{'rm-hide': loading}"
  [hidden]="user"
  [objectDefinition]="appUserDef"
  [placeSaveToRight]="true"
  (saveEmitter)="onValidateClick($event)"
>
</rm-reactive-forms>
<c-progress variant="striped" *ngIf="loading" [value]="100" animated class="mb-3">
  {{'loading' | translate}}
</c-progress>

