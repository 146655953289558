import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { BadgeModule } from 'primeng/badge';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { RMToasterService } from '@rm-services';
import { modes } from '@rm-static';
import { TranslateModule } from '@ngx-translate/core';
import { UploadTaskComponent } from '../upload-input/upload-task/upload-task.component';
import { fileStoringWorkflows } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-drag-and-drop-file-upload',
  templateUrl: './drag-and-drop-file-upload.component.html',
  standalone: true,
  imports: [
    FileUploadModule, ButtonModule, BadgeModule, 
    ProgressBarModule, ToastModule, CommonModule, TranslateModule,
    UploadTaskComponent
  ]
})
export class DragAndDropFileUploadComponent {
  @Input() fileType: 'csv' | 'excel' | '' = '';
  @Input({required: true})
  agentRef: string;
  @Input({required: true})
  companyRef: string;
  @Output()
  fileUrlReady : EventEmitter<{ downloadURL: string; fileName: string; }> = new EventEmitter<{ downloadURL: string; fileName: string; }>();
  public files: File[] = [];
  public file: File;
  totalSize: number = 0;
  totalSizePercent: number = 0;
  uploadedFiles: any[] = [];
  public workflow = fileStoringWorkflows.createUsers;
  constructor(
    private messageService: RMToasterService
  ) { }

  choose() {
    // callback();
  }

  public selectFile(file: FileList) {
    const selectedFile = file.item(0);
    if (!selectedFile) {
      return;
    }
    this.file = selectedFile;
    const setI = setInterval(() => {
      if (this.file) {
        if (this.totalSizePercent > 89) {
          clearInterval(setI);
          return;
        }
        this.totalSizePercent += 5;
        clearInterval(setI);
      }
    }, 20);
  }

  public onFileUploaded(response: { downloadURL: string; fileName: string; } | null) {
    if (!response) {
      return;
    }
    this.totalSizePercent = 101;
    setTimeout(() => {
      this.fileUrlReady.emit(response);
    }, 1000);
  }

  onClearTemplatingUpload(clear: any) {
    clear();
    this.totalSize = 0;
    this.totalSizePercent = 0;
  }

  onTemplatedUpload() {
    this.messageService.showToast({ 
      mode: modes.success, 
      title: 'Success', 
      message: 'File Uploaded', 
      duration: 3000,
      isVisible: true
    });
  }

  uploadEvent() {}

  public downloadTemplate() {
    if (this.fileType === 'csv') {
      window.open('/assets/files/importer_templater.csv', '_blank');
    } else {
      window.open('/assets/files/importer_templater.xlsx', '_blank');
    }
  }

}