import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserSituation, CardSituation, Treezor, WalletSituation, ExpenseLine, ExpenseLineStatus,
  AppRoles, ObjectDefList, RoadMateRefundRequest, InstallmentRefund, FormDefinition, AutoRefund,
  RefundRequestType, error_messages, SettingNames, SupportConversation, RoadMateMath } from '@roadmate/roadmate-common';
import moment from 'moment-timezone';
import { FireFunctionsService, FireStoreService, RMToasterService } from '@rm-services';
import { getTableSettings, transforToIsoDate } from '@rm-utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsComponent } from '../reactive-forms/reactive-forms.component';
import { Ng2SmartTableComponent } from '../migration/tables/ng2-smart-table.component';
import { ExpenseLineStatusComponent } from '../expenseline-status/expenseline-status.component';
import { MonthZeroIndexPipe, TruncPipe, DatePipe } from '../pipes';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { VarDirective } from '../pipes/var.directive';
import { ButtonDirective, ColComponent, FormCheckComponent, FormCheckInputDirective, FormCheckLabelDirective, RowComponent } from '@coreui/angular';

@Component({
  selector: 'rm-user-situation',
  templateUrl: './user-situation.component.html',
  styleUrls: ['./user-situation.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsComponent,
    Ng2SmartTableComponent,
    ExpenseLineStatusComponent,
    MonthZeroIndexPipe, TruncPipe, VarDirective, DatePipe,
    NgClass, NgIf, NgFor,
    RowComponent, ColComponent, ButtonDirective,
    FormCheckComponent, FormCheckLabelDirective, FormCheckInputDirective,
  ]
})
export class UserSituationComponent implements OnInit {
  @Input() public situation: UserSituation;
  @Input() public email: string;
  @Input() public set requestInHand(value: RoadMateRefundRequest) {
    this.currentRequest = value;
  }
  @Input() public set conversationInHand(value: SupportConversation) {
    this.conv = value;
  }
  @Output() public closeEmitter = new EventEmitter<boolean>();
  @Output() public requestRefreshEmitter = new EventEmitter<boolean>();
  @Output() public updatedRequestRefundEmitter = new EventEmitter<RoadMateRefundRequest>();
  public byPassCard = false;
  public validating = false;
  private conv: SupportConversation;
  public loading = false;
  public generalCompanyInformation = '';
  public generalAgentInformation = '';
  public detailsOfCompany = '';
  public currentRequest: RoadMateRefundRequest | undefined;
  isCardOk = false;
  isWalletOk = false;
  isExpenseLineOk = false;
  hasBeneficiary = false;
  public isThereAValidTransportAutoRefund = false;
  public automationOptAttached = false;
  public installementOptAttached = false;
  public card: CardSituation | null;
  public wallet: WalletSituation;
  public roles: AppRoles;
  public appUser: Partial<Treezor.AppUser>;
  public company: Partial<Treezor.User.Definition>;
  public isOverPTLimit = false;
  public remainingAllowancePT = 0;
  public exp: ExpenseLine;
  public requestsSettings: any;
  public ikvRequestsSettings: any;
  public mobilityAccountSetting: any;
  public requests: RoadMateRefundRequest[] = [];
  public isMobilityAccountOk = true;
  public installmentRefuds: InstallmentRefund[] = [];
  public autoRefunds: AutoRefund[] = [];
  public autoRefund: Partial<AutoRefund>;
  public autoRefundSetting: any;
  public autoRefundDef: FormDefinition;
  public installementRefundObject: Partial<InstallmentRefund>;
  public walletAndExpLineMissAligned = false;
  public expStatus = ExpenseLineStatus;
  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private translate: TranslateService,
    private toaster: RMToasterService
  ) { }

  ngOnInit(): void {
    this.setTable();
    this.card = this.situation?.cards?.length ? this.situation.cards[0] : null;
    this.roles = this.situation?.acl;
    this.requests = this.situation?.requests || [];
    if (this.situation.mobilityAccount) {
      if (
          this.situation.mobilityAccount.PMD >= 700 ||
          this.situation.mobilityAccount.PT >= 300 ||
          this.situation.mobilityAccount.FMD >= 600
        ) {
        this.isMobilityAccountOk = false;
      }
    }
    if (
        this.card && 
        this.card.isLive && 
        !this.card.isRevoked && 
        this.card.lockStatus === Treezor.Card.StatusCode.UNLOCK &&
        moment().isBefore(moment(this.card.expiryDate, 'YYYY-MM-DD')) && 
        this.card.pinStatusValid
      ) {
      this.isCardOk = true;
    }
    if (this.situation?.wallets?.length) {
      const [wallet] = this.situation.wallets;
      this.wallet = wallet;
      this.isWalletOk = wallet.sold > 0;
    }
    
    if (this.situation?.expenseLines?.length && this.currentRequest) {
      if (this.situation.wallets.length && this.situation.expenseLines.length) {
        const [wallet] = this.situation.wallets;
        const exp = this.situation.expenseLines.find(el => el.ref === this.currentRequest?.expenseLine);
        if (exp && exp.status === ExpenseLineStatus.Active) {
          this.walletAndExpLineMissAligned = wallet.sold < exp.remainingBalance || parseFloat(`${wallet.authorizedBalance}`) < exp.authorizedBalance;
        }
      }
      if (!this.currentRequest) {
        return;
      }
      const exp = this.situation.expenseLines.find(el => el.ref === this.currentRequest?.expenseLine);
      if (exp) {
        this.exp = exp;
        this.isExpenseLineOk = exp && exp.authorizedBalance > 0 && exp.status === ExpenseLineStatus.Active;
      }
    } else if (this.situation?.expenseLines?.length) {
      const [exp] = this.situation.expenseLines;
      this.exp = exp;
      this.isExpenseLineOk = exp && exp.authorizedBalance > 0 && exp.status === ExpenseLineStatus.Active;
    }
    if (this.situation?.appUser) {
      this.appUser = this.situation.appUser;
    }
    if (this.situation?.company) {
      this.company = this.situation.company;
    }
    if (
        this.currentRequest &&
        this.currentRequest.type === RefundRequestType.REFUND_REQUEST_PRIME_TRANSPORT &&
        this.situation.mobilityAccount &&
        this.situation.mobilityAccount.PT + this.currentRequest.amount > 400
      ) {
      this.isOverPTLimit = true;
      this.remainingAllowancePT = RoadMateMath.normalize(400 - this.situation.mobilityAccount.PT);
    }
    if (this.currentRequest) {
      if (this.currentRequest.type !== RefundRequestType.REFUND_REQUEST_IKV) {
        this.getUserInstallmentRefunds();
        this.getUserAutoRefunds();
      }
      this.getComapnyCustomInformation();
    }
  }

  // public async getComapnyInformation() {
  //   let {agentRef, companyRef} = this.currentRequest ?? {};
  //   if (this.conv) {
  //     agentRef = this.conv.agentRef;
  //     companyRef = this.conv.companyRef;
  //   }
  //   if (!agentRef || !companyRef) {
  //     throw new Error('Missing agentRef or companyRef');
  //   }
  //   const companySetting = await this.fs.getCompanyDefaultSetting(
  //     agentRef,
  //     companyRef,
  //     SettingNames.COMPANY_GENERAL_INFORMATION
  //   );
  //   if (companySetting?.value) {
  //     this.generalCompanyInformation = companySetting.value;
  //   }
  //   const agentSetting = await this.fs.getAgentDefaultSetting(
  //     agentRef,
  //     SettingNames.COMPANY_GENERAL_INFORMATION
  //   );
  //   if (agentSetting?.value) {
  //     this.generalAgentInformation = agentSetting.value;
  //   }
  // }

  public async makeExpenseLineActive() {
    if (!this.exp?.ref) {
      return;
    }
    this.loading = true;
    try {
      const res = await this.ff.saRmSR({
        email: this.exp.email,
        category: "Expenseline",
        ref: this.exp.ref,
        task: "setNewExpenseLine"
      });
      if (!res.error) {
        this.toaster.showSuccessToast('La ligne de dépense a bien été activée.');
      } else {
        this.toaster.showGenericError('L\'activation de la ligne de dépense a échoué.');
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public async alignWalletAndExpLine() {
    if (!this.wallet || !this.exp?.ref || !this.exp?.email) {
      return;
    }
    this.loading = true;
    try {
      const res = await this.ff.saRmSR({
        email: this.exp.email,
        category: "Expenseline",
        ref: this.exp.ref,
        task: "align-expenseline-with-wallet"
      });
      if (!res.error) {
        this.toaster.showSuccessToast('Le wallet a bien été mis à jour.');
      } else {
        this.toaster.showGenericError('La mise à jour du wallet a échoué.');
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private async getComapnyCustomInformation() {
    const {agentRef, companyRef} = this.currentRequest ?? {};
    if (!agentRef || !companyRef) {
      return;
    }
    const settings = await this.fs.getCompanyDefaultSetting(
      agentRef,
      companyRef,
      SettingNames.COMPANY_GENERAL_INFORMATION
    );
    if (settings?.value) {
      this.detailsOfCompany = settings.value;
    }
  }

  public close(value: boolean) {
    this.closeEmitter.emit(value);
  }

  private async getUserInstallmentRefunds () {
    if (!this.currentRequest) {
      throw new Error('Missing currentRequest');
    }
    const email = this.email;
    this.installmentRefuds = await this.fs.getPreviousInstallmentRefunds(email);
    this.autoRefundSetting = await getTableSettings(this.autoRefundDef, this.translate);
    const year = (new Date()).getFullYear();
    const dayOfMonth = (new Date()).getDate();
    this.installementRefundObject = {
      email: this.currentRequest.email,
      agentRef: this.currentRequest.agentRef,
      companyRef: this.currentRequest.companyRef,
      employeeRef: this.currentRequest.uid,
      refundRef: this.currentRequest.ref,
      isActivated: false,
      type: this.currentRequest.type,
      dayOfTheMonth: dayOfMonth <= 10 ? 10 : dayOfMonth >= 20 ? 20 : dayOfMonth,
      contractStartDate: this.currentRequest.invoices.date.length >= 10 ? transforToIsoDate(this.currentRequest.invoices.date) : '',
      contractEndDate: `${year}-12-31`,
      isValid: false,
      amount: this.currentRequest.installmentAmount ?? this.currentRequest.amount,
      refundHistory: [],
      articleToRefund: this.currentRequest.merchantName,
      totalAmountToRefund: this.currentRequest.invoices.amount,
      totalAmountRefundedSoFar: 0
    }
  }

  public saveAutomationObject(automationOpt: AutoRefund) {
    this.autoRefund = automationOpt;
    this.autoRefund.validatedBy = this.fs.currentAppUser.email;
    this.autoRefund.validationDate = (new Date()).toISOString();
    this.automationOptAttached = true;
  }

  public saveInstallementObject(installementOpt: InstallmentRefund) {
    this.installementRefundObject = {
      ...this.installementRefundObject,
      ...installementOpt
    };
    this.installementRefundObject.validatedBy = this.fs.currentAppUser.email;
    this.installementRefundObject.validationDate = (new Date()).toISOString();
    this.installementOptAttached = true;
  }

  private async getUserAutoRefunds () {
    if (!this.currentRequest) {
      throw new Error('Missing currentRequest');
    }
    const email = this.email;
    this.autoRefunds = await this.fs.getPreviousAutoRefunds(email);
    this.autoRefundSetting = await getTableSettings(this.autoRefundDef, this.translate);
    const year = (new Date()).getFullYear();
    // const isThereAValidTransportAutoRefund = this.autoRefunds.find(
    //   el => el.type === RefundRequestType.REFUND_TRANSPORT_TITLE &&
    //     moment().isBefore(moment(el.contractEndDate, 'YYYY-MM-DD'))
    // );
    // if (isThereAValidTransportAutoRefund) {
    //   // There Can't be two active Transport Auto refunds
    //   this.isThereAValidTransportAutoRefund = true;
    //   return;
    // }
    const today = (new Date()).toISOString().substring(0,10);
    const dayOfMonth = (new Date()).getDate();
    this.autoRefund =  {
      email: this.currentRequest.email,
      agentRef: this.currentRequest.agentRef,
      companyRef: this.currentRequest.companyRef,
      employeeRef: this.currentRequest.uid,
      refundRef: this.currentRequest.ref,
      isActivated: false,
      type: this.currentRequest.type,
      dayOfTheMonth: dayOfMonth >= 20 ? 20 : dayOfMonth <= 10 ? 10 : dayOfMonth,
      contractStartDate: this.currentRequest.invoices.date.length >= 10 ? transforToIsoDate(this.currentRequest.invoices.date) : today,
      contractEndDate: `${year}-12-31`,
      isValid: false,
      amount: this.currentRequest.amount,
      articleToRefund: this.currentRequest.merchantName,
    };
  }

  private async setTable() {
    const list = await this.fs.getObjectsList();
    
    const ikvReq = JSON.parse(JSON.stringify(list[ObjectDefList.ikvRequest]));
    this.ikvRequestsSettings = await getTableSettings(ikvReq, this.translate);
    const req = JSON.parse(JSON.stringify(list[ObjectDefList.refundRequests]));
    if (req.email) {
      delete req.email;
    }
    if (req.label) {
      req.label.lightDisplay = true;
    }
    this.requestsSettings = await getTableSettings(req, this.translate);
    const mobAccount = JSON.parse(JSON.stringify(list[ObjectDefList.mobilityAccount]));
    this.mobilityAccountSetting = await getTableSettings(mobAccount, this.translate);
    this.autoRefundDef = JSON.parse(JSON.stringify(list[ObjectDefList.autoRefunds]))
    //mobility-account
  }

  public async rejectRequest() {
    if (!this.currentRequest) {
      throw new Error('Missing currentRequest');
    }
    this.validating = true;
    try {
      if (!this.currentRequest.ref) {
        this.toaster.showGenericError('Il manque la ref de la demande');
        this.close(false);
        return;
      }
      await this.updateRequest(this.currentRequest);
      const response = await this.ff.processUserRefundTransfer(this.currentRequest.ref, false, this.currentRequest.type);
      if (response.message === error_messages.OK) {
        this.toaster.showSuccessToast(`La tâche a bien été traitée.`);
        this.close(true);
      } else {
        this.toaster.showGenericError(response.message);
        this.close(false);
      }
    } catch (e) {
      console.error('validateRequest', e);
    } finally {
      this.validating = false;
    }
  }

  public async validateRequest() {
    if (!this.currentRequest) {
      throw new Error('Missing currentRequest');
    }
    this.validating = true;
    try {
      await this.updateRequest(this.currentRequest);
      const response = await this.ff.processUserRefundTransfer(this.currentRequest.ref ?? '', true, this.currentRequest.type, this.byPassCard);
      if (response.message === error_messages.OK) {
        this.toaster.showSuccessToast(`Le remboursement s'est bien déroulé.`);
        if (this.currentRequest.requestAutomation && this.currentRequest && this.autoRefund.isValid  && this.autoRefund.isActivated) {
          await this.fs.addNewAutoRefund(this.autoRefund as AutoRefund);
          this.toaster.showSuccessToast(`La demande de remboursement automatique a été enregistrée.`);
        } else if (this.currentRequest.requestInstallment && this.installementOptAttached && this.installementRefundObject.isValid && this.installementRefundObject.isActivated) {
          const refundUpToDate = await this.fs.getSARefundRequests(this.currentRequest.ref ?? '');
          if (refundUpToDate?.ref) {
            this.installementRefundObject.refundHistory = [
              {
                refundAmount: refundUpToDate.amount,
                createdAt: refundUpToDate.createdAt,
                updatedAt: refundUpToDate.createdAt,
                payoutRef: `${refundUpToDate.payoutRef}`,
                transactionId: `${refundUpToDate.paymentId}`,
                month: (new Date()).getMonth(),
                refundRef: refundUpToDate.ref,
                processDate: (new Date()).toISOString(),
              }
            ];
          }
          await this.fs.addNewInstallementRefund(this.installementRefundObject as InstallmentRefund);
          this.toaster.showSuccessToast(`La demande de remboursement par échéance a été enregistrée.`);
        }
        this.close(true);
      } else {
        this.toaster.showGenericError(response.message);
        this.close(false);
      }
    } catch (e) {
      console.error('validateRequest', e);
    } finally {
      this.validating = false;
    }
  }

  private async updateRequest(request: RoadMateRefundRequest) {
    if (!request.ref) {
      return;
    }
    this.loading = true;
    try {
      await this.fs.updateRefundRequest(request);
      this.currentRequest = request;
      this.updatedRequestRefundEmitter.emit(request);
      this.toaster.showSuccessToast('La demande de remboursement a bien été mise à jour.');
    } catch (e) {
      console.error('Enable to update request', e);
      this.toaster.showGenericError('La mise à jour de la demande a échoué.');
    } finally {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    // this.editor.destroy();
  }
}
