import { 
  Component,
  // ElementRef, AfterViewInit, OnInit, ViewChild
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { IconDirective } from '@coreui/icons-angular';
import {
  ColComponent,
  ContainerComponent,
  RowComponent,
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective
} from '@coreui/angular';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { actions, Agent, appRoles, kybStatus, Kyc, RoadMateEnvironmentNameSpace, Treezor, rm_storageKeys, AppRoles } from '@roadmate/roadmate-common';
import { environment } from '../../../environments/environment';
import { FireStoreService, ModalParcel, ModalService } from '@rm-services';
import { FireAuthService, MessengerService } from '@rm-services';
import { RmModalComponent } from '../../modals/rm-modals/rm-modal.component';


import { DefaultFooterComponent, DefaultHeaderComponent } from './';
import { adminLinks, appRoutes, loggedInNotConfigured,
  loggedInNotValidated, loggedOut, superadminLinks, 
  agentLinks, accountManagerLinks, amCompanyNotSelected, 
  employeeNavLinks, employeeNotSetUp } from '@rm-static';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { RMToasterComponent } from '@rm-modules/rm-toaster/rm-toaster.component';
import { TranslateModule } from '@ngx-translate/core';
import { cilAccountLogout } from '@coreui/icons';

// function isOverflown(element: HTMLElement) {
//   return (
//     element.scrollHeight > element.clientHeight ||
//     element.scrollWidth > element.clientWidth
//   );
// }

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarBrandComponent,
    RouterLink,
    IconDirective,
    NgScrollbar,
    SidebarNavComponent,
    SidebarFooterComponent,
    SidebarToggleDirective,
    // SidebarTogglerDirective,
    DefaultHeaderComponent,
    ShadowOnScrollDirective,
    ContainerComponent,
    RouterOutlet,
    DefaultFooterComponent,
    RmModalComponent,
    NgIf,
    AsyncPipe,
    NgFor,
    TranslateModule,
    ColComponent,
    RowComponent
  ]
})
export class DefaultLayoutComponent {
  minimized = false;
  public dashVersion = RoadMateEnvironmentNameSpace.dashVersion;
  public navItems = [...loggedOut];
  public opened = false;
  public isBiggerScreen = false;
  public currentPage = '';
  public routes = appRoutes;
  public adminlinks = [...adminLinks];
  public superadminlinks = [...superadminLinks];
  public companyName = '';
  public companyImg = '';
  public email = '';
  public fullName = '';
  public themeClass: string;
  public env = environment.env;
  public isAdmin = false;
  public userAppRole = '';
  public isSuperAdmin = false;
  public isAgent = false;
  public isEmployee = false;
  public isAccountManager = false;
  public agentRef = '';
  public flash = true;
  public navbarBrandFull = {src: 'assets/svg/roadmate.svg', width: 89, height: 25, alt: 'RoadMate Logo', background: 'black'};
  public navbarBrandMinimized = {src: 'assets/img/brand/roadmate.svg', width: 30, height: 30, alt: 'RoadMate Logo'};
  public agentLogo = '/assets/img/logo2.png';
  public agent: Agent;
  public isLoggedIn = false;
  public parcel: Observable<ModalParcel[]>;
  public selectedCompany: Treezor.User.Definition | null = null;
  public year = (new Date()).getFullYear();
  public loginAs = '';
  public currentEmail = '';
  public icons = {
    cilAccountLogout
  }
  public rmLogos = {
    dark: {
      square: {src: '/assets/img/brand/roadmate-square-white.svg', width: 35, height: 35},
      rectangle: {src: '/assets/img/brand/RoadMate-white.svg', width: 118, height: 32}
    },
    light: {
      square: {src: '/assets/img/brand/roadmate.svg', width: 35, height: 35},
      rectangle: {src: '/assets/img/brand/RoadMate-blue.svg', width: 118, height: 32}
    }
  }
  public selectedLogos = {
    square: {...this.rmLogos.light.square},
    rectangle: {...this.rmLogos.light.rectangle}
  }
  constructor(
    private router: Router,
    // translate: TranslateService,
    // private ff: FireFunctionsService,
    private fs: FireStoreService,
    private messenger: MessengerService,
    private modalService: ModalService,
    public auth: FireAuthService,
  ) {
    this.parcel = this.modalService.messenger.asObservable();
    this.router.events.subscribe(
      value => {
        if (value instanceof NavigationEnd) {
          const state: NavigationEnd = value;
          let currentRoute = '';
          Object.values(appRoutes).some(route => {
            if (state.urlAfterRedirects.indexOf(route) > -1) {
              currentRoute = route;
              return true;
            }
            return false;
          });
          this.currentPage = currentRoute;
        }
      }
    );
  }

  public ngOnInit() {
    if (this.auth.isLoggedIn && this.auth.userRoles) {
      this.setRoleDashboard(this.auth.userRoles);
    }
    this.messenger.parcel.asObservable().subscribe(parcel => {
      this.isLoggedIn = this.auth.isLoggedIn;
      switch (parcel.action) {
        case actions.goToHomeScreen: {
          this.router.navigateByUrl(`/${this.navItems[0].url}`);
          break;
        }
        case actions.customClaims: {
          this.agentRef = parcel.data.agentRef;
          break;
        }
        case actions.roles:
          this.setRoleDashboard(parcel.data);
          break;
        case actions.theme: {
          if (parcel.data === 'dark') {
            this.selectedLogos = {
              square: {...this.rmLogos.dark.square},
              rectangle: {...this.rmLogos.dark.rectangle}
            }
          } else {
            this.selectedLogos = {
              square: {...this.rmLogos.light.square},
              rectangle: {...this.rmLogos.light.rectangle}
            }
          }
          break;
        }
        // case actions.login:
        //   if (this.auth.isLoggedIn) {
        //     this.ff.logUserLogin();
        //   }
        //   break;
        case actions.logout:
          this.navItems = loggedOut;
          this.userAppRole = '';
          this.isLoggedIn = false;
          this.companyName = this.companyImg = this.email = this.fullName = '';
          break;
        case actions.userCompany:
          this.companyName = parcel.data.legalName;
          this.companyImg = parcel.data.img ? parcel.data.img : ''
          break;
        case actions.amSelectCompany:
          this.companyName = parcel.data.legalName;
          this.selectedCompany = parcel.data;
          this.setAMNavLinks();
          break;
        case actions.accountManagerAgent :
          this.companyName = parcel.data.legalName;
          break;
        case actions.companyDetails:
          const company: Treezor.User.Definition = parcel.data;
          if (this.auth && this.auth.userRoles.admin && this.auth.isLoggedIn && this.loginAs !== appRoles.employee) {
            this.navItems = company.kycReview === Treezor.User.kycReview.VALIDATED ?
            adminLinks : // Ne pas afficher tous les liens si admin n'a pas configuré le compte
            loggedInNotValidated
          }
          break;
        case actions.kyc: 
          const kyc = parcel.data as Kyc;
          const appUser = this.fs.currentAppUser;
          if (
            !appUser.mobile || !appUser.hasConfirmedPhone
            || kyc.status !== kybStatus.kyb_validate_by_treezor
            || (
              this.fs.currentAppUser?.userGroupIds?.some(el => el.value === 'FMD')
              && !this.fs.currentAppUser.attestations?.length
            )
          ) {
            this.navItems = employeeNotSetUp;
          } else {
            this.navItems = employeeNavLinks;
          }
          break;
        case actions.ordersCount:
          const count: number = parcel.data;
          if (count === 0) {
            this.navItems = loggedInNotConfigured;
          }
          break;
        case actions.agent:
          this.agent = parcel.data;
          if (this.agent.largeLogo.length) {
            const [imgUrl] = this.agent.largeLogo;
            const url = imgUrl.url ? imgUrl.url : this.navbarBrandFull.src;
            this.navbarBrandFull = {
              ...this.navbarBrandFull,
              src: url
            }
            this.agentLogo = url;
            this.flash = false;
            setTimeout(() => this.flash = true, 250);
          }
          if (this.agent.squareLogo.length) {
            const [imgUrl] = this.agent.squareLogo;
            const url = imgUrl.url ? imgUrl.url : this.navbarBrandMinimized.src;
            this.navbarBrandMinimized = {
              ...this.navbarBrandMinimized,
              src: url
            }
          }
          if (this.agent.cssClass) {
            const body = document.body;
            localStorage.setItem('agentCssClass', this.agent.cssClass);
            if (!body.classList.contains(this.agent.cssClass)) {
              body.classList.add(this.agent.cssClass);
            }
          } else {
            const body = document.body;
            localStorage.setItem('agentCssClass', '');
            if (body.classList.contains('agent')) {
              body.className = ''
            }
          }
          break;
        default:
        break;
      }
    });
  }

  public async logout() {
    await this.router.navigateByUrl(`/${appRoutes.logout}`);
  }

  private async setRoleDashboard(userRoles: AppRoles) {
    this.currentEmail = this.auth.user.email ?? '';
    this.loginAs = localStorage.getItem(this.currentEmail) as string;
    this.email = this.auth.user.email as string;
    this.isSuperAdmin = !!userRoles.superadmin;
    this.isAdmin = !!userRoles.admin;
    this.isAgent = !!userRoles.agent;
    this.isAccountManager = !!userRoles.accountmanager;
    this.isEmployee =  !!userRoles.employee;
    this.userAppRole = this.isSuperAdmin ? appRoles.superadmin :
    (this.isAdmin ? appRoles.admin : (this.isAgent ? appRoles.agent : (this.isAccountManager ? appRoles.accountManager: appRoles.employee)));
    if (this.isSuperAdmin) {
      this.navItems = this.superadminlinks;
    } else if (this.isAgent) {
      this.navItems = agentLinks;
    } else if (this.isAdmin && (this.loginAs === appRoles.admin || !this.loginAs)) {
      if (this.auth && this.auth.userRoles.admin && this.auth.isLoggedIn && this.loginAs !== appRoles.employee && this.fs.currentCompany) {
        this.navItems = this.fs.currentCompany.kycReview === Treezor.User.kycReview.VALIDATED ?
        adminLinks : // Ne pas afficher tous les liens si admin n'a pas configuré le compte
        loggedInNotValidated;
        return;
      }
      this.navItems = [];
      return;
    } else if (this.isAccountManager && this.loginAs !== appRoles.employee) {
      this.setAMNavLinks();
    } else if (this.isEmployee ) {
      await this.setEmployeeNavLinks();
    } else {
      this.navItems = loggedOut;
    }
  }

  private async setEmployeeNavLinks () {
    this.navItems = employeeNavLinks;
  }

  private setAMNavLinks () {
    if (this.selectedCompany) {
      this.navItems = accountManagerLinks;
    } else {
      this.navItems = amCompanyNotSelected;
    }
  }

  public updateProfile() {
    if(this.isAgent && this.userAppRole === appRoles.agent) {
      this.router.navigateByUrl(`/${appRoutes.agentProfile}`);
    } else if (this.isAccountManager && this.userAppRole === appRoles.accountManager) {
      this.router.navigateByUrl(`/${appRoutes.accountManager}/${appRoutes.settings}/${appRoutes.profile}`);
    } else if (this.isEmployee && this.loginAs === appRoles.employee || (this.isEmployee && !this.isAccountManager && !this.isAdmin)) {
      this.router.navigateByUrl(`/${appRoutes.employee}/${appRoutes.myprofile}`);
    } else if (this.isAdmin && !this.loginAs) {
      this.router.navigateByUrl(`/${appRoutes.admin}/${appRoutes.settings}/${appRoutes.profile}`);
    }
  }

  onScrollbarUpdate($event: any) {
    // if ($event.verticalUsed) {
    // console.log('verticalUsed', $event.verticalUsed);
    // }
  }
}
