import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormDefinition, Merchant, Mid, ObjectDefList } from '@roadmate/roadmate-common';
import { FireStoreService, ModalService } from '@rm-services';
import { getTableSettings } from '@rm-utils';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { NgIf } from '@angular/common';
import { Ng2SmartTableComponent } from '@rm-modules/migration/tables/ng2-smart-table.component';
import { ProgressComponent, TextColorDirective } from '@coreui/angular';

@Component({
  selector: 'rm-assign-mid',
  templateUrl: './assign-mid.component.html',
  styleUrls: ['./assign-mid.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, NgIf, Ng2SmartTableComponent, ProgressComponent, TextColorDirective
  ]
})
export class AssignMidComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public merchants: Merchant[] = [];
  public seletedMids: Mid[];
  public merchantDef: FormDefinition;
  public settings: any;
  public loading = false;
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.seletedMids = value.seletedMids;
  }

  constructor(
    private fs: FireStoreService,
    private translate: TranslateService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.initList();
  }

  private async initList() {
    this.merchants = await this.fs.getMerchants();
    const list = await this.fs.getObjectsList();
    this.merchantDef = list[ObjectDefList.merchant];
    this.settings = await getTableSettings(this.merchantDef, this.translate);
  }

  public async selectMerchant(data: Merchant) {
    this.loading = true;
    try {
      await this.fs.addMidToMerchant(data, this.seletedMids);
      this.close(true);
    } catch (e) {
      console.error('', e);
      this.close(false);
    } finally {
      this.loading = false;
    }
  }
}
