@switch (fileType) {
  @case('none') {
    <div class="d-flex justify-content-center align-items-center h-100 flex-column">
      <h4 class="ms-2 text-body">
        <svg [cIcon]="icons.cilReload" size="xl"></svg> {{'file_format_unsupported'|translate}}
      </h4>
      @if (file?.url) {
        <div>
          <button cButton color="primary" type="button" (click)="openUrl(file.url)">
            {{'open_url' | translate}}
            <svg [cIcon]="icons.cilLink" size="lg" title=""></svg>
          </button>
        </div>
      }
    </div>
  }
  @case('image') {
    <div class="d-flex justify-content-center align-items-center" style="height: auto; width: 100%;">
      <img [src]="file.url | rm_safe:'url'" alt="file" class="img-fluid" />
    </div>
  }
  @case('pdf') {
    <div class="d-flex justify-content-center align-items-center h-100">
      <iframe [src]="file.url | rm_safe:'url'"></iframe>
    </div>
  }
}