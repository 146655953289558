import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { RoadMateTransfer } from '@roadmate/roadmate-common';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe } from '@rm-modules/pipes';
import { ModalService } from '@rm-services';

@Component({
  selector: 'rm-confirm-refund',
  templateUrl: './confirm-refund.component.html',
  styleUrls: ['./confirm-refund.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, CurrencyPipe
  ]
})
export class ConfirmRefundComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public transfer: RoadMateTransfer;
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.transfer = value;
  }
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {

  }

}
