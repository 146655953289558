import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';
import { appRoutes } from './static';
import { appRoles } from '@roadmate/roadmate-common';
import { MasterCanActivate } from '@rm-services';

export const routes: Routes = [
  {
    path: '',
    redirectTo: appRoutes.login,
    pathMatch: 'full',
  },
  { path: appRoutes.logout,
    loadComponent: () => import('./routes/unauthenticated/logout/logout.component').then(m => m.LogoutComponent),
    data: {
      title: 'Déconnexion'
    }
  },
  { path: appRoutes.login,
    loadComponent: () => import('./routes/unauthenticated/login/login.component').then(m => m.LoginComponent),
    data: {
      title: 'Connexion'
    }
  },
  { path: appRoutes.invoiceBundle,
    loadComponent: () => import('./routes/unauthenticated/invoice-bundle/invoice-bundle-page.component').then(m => m.InvoiceBundlePageComponent),
    data: {
      title: 'Factures et justificatifs'
    }
  },
  { path: appRoutes.unavailable,
    loadComponent: () => import('./routes/unauthenticated/page500/page500.component').then(m => m.Page500Component),
    data: {
      title: 'Page introuvable'
    }
  },
  { path: appRoutes.unauthorized,
    loadComponent: () => import('./routes/unauthenticated/unauthorized/unauthorized.component').then(m => m.UnauthorizedComponent),
    data: {
      title: 'Autorisation insuffisante'
    }
  },
  { path: appRoutes.scheduledTransfer,
    loadComponent: () => import('./routes/unauthenticated/scheduled-transfer/unauth-scheduled-transfer.component').then(m => m.UnAuthScheduledTransferComponent),
    data: {
      title: 'Attribution de budget programmée'
    }
  },
  { path: appRoutes.subscribe,
    loadComponent: () => import('./routes/unauthenticated/register/register.component').then(m => m.SubscribeComponent),
    data: {
      title: 'Créer un compte'
    }
  },
  { path: appRoutes.activateAccount,
    loadComponent: () => import('./routes/unauthenticated/activate-account/activate-account.component').then(m => m.ActivateAccountComponent),
    data: {
      title: 'Activation de compte'
    }
  },
  { path: appRoutes.updatePassword,
    loadComponent: () => import('./routes/unauthenticated/update-password/update-password.component').then(m => m.UpdatePasswordComponent),
    data: {
      title: 'Modification de mot de passe'
    }
  },
  { path: `${appRoutes.updatePassword}/:validationCode`,
    loadComponent: () => import('./routes/unauthenticated/update-password/update-password.component').then(m => m.UpdatePasswordComponent),
    data: {
      title: 'Modification de mot de passe'
    }
  },
  // EMPLOYEE ROUTES
  {
    path: appRoutes.employee,
    component: DefaultLayoutComponent,
    data: {
      title: 'Bénéficiaire',
      [appRoles.employee]: true,
    },
    canActivate: [MasterCanActivate],
    loadChildren: () => import('./routes/employee/employee.routing').then(m => m.employeeRoutes)
  },
  // ADMIN ROUTES
  {
    path: appRoutes.admin,
    component: DefaultLayoutComponent,
    data: {
      title: 'Admin',
      [appRoles.admin]: true,
    },
    canActivate: [MasterCanActivate],
    loadChildren: () => import('./routes/admin/admin.routes').then(m => m.adminRoutes),
  },
  // AM ROUTES
  {
    path: appRoutes.accountManager,
    component: DefaultLayoutComponent,
    data: {
      title: 'Gestionnaire de compte',
      [appRoles.accountManager]: true,
    },
    canActivate: [MasterCanActivate],
    loadChildren: () => import('./routes/account-manager/account-manager.routing').then(m => m.accountManagerRoutes)
  },
  // SUPER ADMIN ROUTES
  {
    path: appRoutes.superadmin,
    component: DefaultLayoutComponent,
    loadChildren: () => import('./routes/superadmin/superadmin.module').then(m => m.SuperAdminModule),
    data: {
      title: 'Super Admin',
      [appRoles.superadmin]: true,
    },
    canActivate: [MasterCanActivate],
  },
  { path: '**', 
    loadComponent: () => import('./routes/unauthenticated/page404/page404.component').then(m => m.Page404Component),
    data: {
      title: 'Page introuvable'
    } },
]; 


// export const oldroutes: Routes = [
//   {
//     path: '',
//     redirectTo: 'dashboard',
//     pathMatch: 'full'
//   },
//   {
//     path: '',
//     component: DefaultLayoutComponent,
//     data: {
//       title: 'Home'
//     },
//     children: [
//       {
//         path: 'dashboard',
//         loadChildren: () => import('./views/dashboard/routes').then((m) => m.routes)
//       },
//       {
//         path: 'theme',
//         loadChildren: () => import('./views/theme/routes').then((m) => m.routes)
//       },
//       {
//         path: 'base',
//         loadChildren: () => import('./views/base/routes').then((m) => m.routes)
//       },
//       {
//         path: 'buttons',
//         loadChildren: () => import('./views/buttons/routes').then((m) => m.routes)
//       },
//       {
//         path: 'forms',
//         loadChildren: () => import('./views/forms/routes').then((m) => m.routes)
//       },
//       {
//         path: 'icons',
//         loadChildren: () => import('./views/icons/routes').then((m) => m.routes)
//       },
//       {
//         path: 'notifications',
//         loadChildren: () => import('./views/notifications/routes').then((m) => m.routes)
//       },
//       {
//         path: 'widgets',
//         loadChildren: () => import('./views/widgets/routes').then((m) => m.routes)
//       },
//       {
//         path: 'charts',
//         loadChildren: () => import('./views/charts/routes').then((m) => m.routes)
//       },
//       {
//         path: 'pages',
//         loadChildren: () => import('./views/pages/routes').then((m) => m.routes)
//       }
//     ]
//   },
//   {
//     path: '404',
//     loadComponent: () => import('./views/pages/page404/page404.component').then(m => m.Page404Component),
//     data: {
//       title: 'Page 404'
//     }
//   },
//   {
//     path: '500',
//     loadComponent: () => import('./views/pages/page500/page500.component').then(m => m.Page500Component),
//     data: {
//       title: 'Page 500'
//     }
//   },
//   {
//     path: 'login',
//     loadComponent: () => import('./views/pages/login/login.component').then(m => m.LoginComponent),
//     data: {
//       title: 'Login Page'
//     }
//   },
//   {
//     path: 'register',
//     loadComponent: () => import('./views/pages/register/register.component').then(m => m.RegisterComponent),
//     data: {
//       title: 'Register Page'
//     }
//   },
//   { path: '**', redirectTo: 'dashboard' }
// ];
