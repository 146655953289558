import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EmployeeInvoice, error_messages, fileStoringWorkflows, GroupedObject, IKV, Merchant, Mid, Place, PowerSource, RefundDestination, RefundRequestType, RoadMateFile, RoadMateRefundRequest, RoadMateTimeZone, RoadMateTrip, SimpleTrip, Treezor } from '@roadmate/roadmate-common';
import { FileUploadProgress, FireFunctionsService, FireStoreService, ModalService, RMToasterService, UploadFileService } from '@rm-services';
import { AbstractEntryComponent } from '../abstract.entry-component';
import moment from 'moment';
import { Observable } from 'rxjs';
import { CurrencyPipe, DatePipe, RoadMateImagePipe, TruncPipe } from '@rm-modules/pipes';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective, CardBodyComponent, CardComponent, CardTextDirective, ColComponent, ProgressComponent, RowComponent, SpinnerComponent } from '@coreui/angular';
import { cilPlus, cilTrash } from '@coreui/icons';
import { IconDirective } from '@coreui/icons-angular';

@Component({
  selector: 'rm-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
  standalone: true,
  imports: [
    NgIf, TranslateModule, 
    NgFor, TruncPipe,CurrencyPipe, NgSwitch, NgSwitchCase, NgSwitchDefault,
    DatePipe, RoadMateImagePipe, CardComponent, CardBodyComponent, CardTextDirective,
    RowComponent, ColComponent, IconDirective, ButtonDirective, SpinnerComponent
  ]
})
export class TransactionDetailsComponent extends AbstractEntryComponent<any> implements OnInit {
  @Input()
  public transaction: GroupedObject<Treezor.Card.CardTransaction>;
  public title: string;
  public loading = false;
  override set data(value: any) {
    if (!value?.transaction) {
      return;
    }
    this.transaction = value.transaction;
  }
  icons = {
    cilTrash,
    cilPlus
  }
  public isIphoneX = false;
  public currentMid: Mid;
  public currentMerchant: Merchant;
  public logo = '/assets/svg/euro.svg';
  public paymentType: 'payment' | 'refund' | 'decline' | 'processing_payment' | 'refund_request' | 'budget_distribution' | 'budget_expired' = 'payment';
  public paymentDate: string;
  public productName: string;
  public isSportingGoods = false;
  public isInsuffisantFund = false;
  public amount: number;
  public merchantName: string;
  public universalLink: string;
  public deepLink: string;
  public storeLink: string;
  public webSite: string;
  public progress: Observable<FileUploadProgress[]>;
  public invoices: EmployeeInvoice;
  public resizingInProgress = false;
  public removingFileUrl = '';
  public openingFile = false;
  public roadmateTrip: Partial<RoadMateTrip>;
  public paymentId: string;
  private firstTransaction: Treezor.Card.CardTransaction;
  public supportingFileLink: string;
  // private subs = [];
  public viewingMerchant = false;
  public setTripDetails = false;
  public refundRequest: IKV;
  public fallbackurl = '/assets/img/placeholder.png';
  public request: RoadMateRefundRequest;
  public translations: {[key: string]: string} = {};
  @ViewChild('fileInput') fileInput: ElementRef;
  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    private uploadService: UploadFileService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    if (this.transaction?.list?.length) {
      this.getMidByRef();
      this.getEmployeeInvoice();
    }
    this.firstTransaction = this.transaction.list[0];
    if (this.firstTransaction?.supportingFileLink) {
      this.supportingFileLink = this.firstTransaction.supportingFileLink;
    }
    this.paymentDate = this.firstTransaction.authorizationIssuerTime;
    if (this.transaction.list.every(tr => !tr.type || tr.type === RefundRequestType.PAYMENT_BY_CARD)) {
      if (this.transaction.list.some(tr => tr.paymentStatus === Treezor.Card.PaymentStatus.Declined)) {
        this.paymentType = 'decline';
        this.isSportingGoods = ['5940', '5941', '5932'].indexOf(this.firstTransaction.mccCode ?? '') > -1 ;
      } else if (this.transaction.list.some(tr => tr.paymentStatus === Treezor.Card.PaymentStatus.Refund)) {
        this.paymentType = 'refund';
      } else if (this.transaction.list?.length === 1 && this.transaction.list[0].paymentStatus === Treezor.Card.PaymentStatus.Accepted) {
        this.paymentType = 'processing_payment';
      }
      this.merchantName = this.firstTransaction.merchantName;
    } else if (this.transaction.list.some(tr => [RefundRequestType.BOTTOMDOWN, RefundRequestType.TOPUP].indexOf(tr.type ?? RefundRequestType.PAYMENT_BY_CARD) > -1)) {
      const tr = this.transaction.list[0];
      this.paymentType = tr.type === RefundRequestType.BOTTOMDOWN ? 'budget_expired' : 'budget_distribution';
    } else {
      this.paymentType = 'refund_request';
    }
    this.productName = this.firstTransaction.productName;
    this.amount = parseFloat(this.firstTransaction.paymentAmount);
    this.paymentId = this.firstTransaction.paymentId;
    if (this.paymentType === 'refund_request') {
      this.getRefundRequest()
    }
  }

  public openAttestation(url: string) {
    if (url) {
      // this.opener.openExternalUrl(url);
      window.open(url, '_blanc');
    }
  }

  private async getRefundRequest() {
    const [tr] = this.transaction.list;
    if (!tr.refundRequestRef) {
      return;
    }
    if (tr.type === RefundRequestType.REFUND_REQUEST_IKV) {
      this.refundRequest = await this.fs.getikvRequest(tr.refundRequestRef) as IKV;
    } else {
      this.refundRequest = await this.fs.getRefundRequest(tr.refundRequestRef) as IKV;
    }
  }

  private async getTrip() {
    const roadmateTrip = await this.fs.getTripByPaymentId(this.paymentId);
    if (!roadmateTrip) {
      this.roadmateTrip = new SimpleTrip();
      this.roadmateTrip.paymentId = this.paymentId;
      this.roadmateTrip.date = this.firstTransaction.authorizationIssuerTime;
      if (this.currentMerchant) {
        this.roadmateTrip.poweredBy = this.currentMerchant.powerSource ?? PowerSource.UNKNOWN;
        this.roadmateTrip.transportType = this.currentMerchant.transportType;
        this.roadmateTrip.vehiculeType = this.currentMerchant.vehiculeType;
      }
      // this.roadmateTrip.ref = await this.fs.createRoadMateTrip(this.roadmateTrip);
    } else {
      this.roadmateTrip = roadmateTrip;
      if (!this.roadmateTrip.departure) {
        this.roadmateTrip.departure = new Place();
      }
      if (!this.roadmateTrip.arrival) {
        this.roadmateTrip.arrival = new Place();
      }
      // this.watchTrip();
    }
  }

  public saveTrip(trip: RoadMateTrip) {
    if (trip) {
      this.roadmateTrip = trip;
    }
    this.setTripDetails = false;
  }

  private async getMidByRef() {
    const mid = this.transaction.list[0].merchantId;
    if (!mid) {
      return;
    }
    const currentMid = await this.fs.getMidByRef(mid);
    if (!currentMid) {
      return
    }
    this.currentMid = currentMid;
    if (this.currentMid?.merchantRef) {
      await this.getMerchant(this.currentMid?.merchantRef);
    }
    await this.getTrip();
  }

  private async getEmployeeInvoice() {
    const paymentId = this.transaction.list[0].paymentId;
    const invoice = await this.fs.getInvoiceByPaymentId(paymentId)
    .catch(e => {
      console.log('No invoice available');
    });
    if (invoice) {
      this.invoices = invoice;
    }
  }

  private async getMerchant(merchantRef: string) {
    const currentMerchant = await this.fs.getMerchantByRef(merchantRef);
    if (!currentMerchant) {
      return;
    }
    this.currentMerchant = currentMerchant;
    if (this.currentMerchant?.logo?.[0].url) {
      this.logo = this.currentMerchant.logo[0].url;
      this.universalLink = this.currentMerchant.applink?.universalLink;
      // if (this.plt.is('iphone')) {
      //   this.deepLink = this.currentMerchant?.applink?.iosDeeplink;
      //   this.storeLink = this.currentMerchant?.applink?.iosStore;
      // } else if (this.plt.is('android')) {
      //   this.deepLink = this.currentMerchant?.applink?.androidDeeplink;
      //   this.storeLink = this.currentMerchant?.applink?.androidStore;
      // }
      this.webSite = this.currentMerchant?.applink?.web;
    }
  }

  public openApp(url: string) {
    // this.opener.openExternalUrl(url);
    window.open(url, '_blank');
  }

  public async openFile(file: RoadMateFile) {
    if (!file?.url) {
      return;
    }
    window.open(file.url, '_blank');
  }

  public async removeFile(file: RoadMateFile) {
    this.removingFileUrl = file.url;
    try {
      await this.fs.removeFileFromInvoice(this.invoices, file);
      this.invoices.files = this.invoices.files.filter(f => f.url !== file.url);
      this.toast.showSuccessToast('INVOICE_WAS_DELETED');
    } catch (e) {
      this.toast.showGenericError('INVOICE_COULD_NOT_BE_DELETED');
      console.error('', e);
    } finally {
      this.removingFileUrl = '';
    }
  }

  public displayMerchant() {
    if (this.currentMerchant) {
      this.viewingMerchant = true;
    }
  }

  override close() {
    if (this.viewingMerchant) {
      this.viewingMerchant = false;
      return;
    }
    if (this.setTripDetails) {
      this.setTripDetails = false;
      return;
    }
    // this.viewCtrl.dismiss();
  }

  public async selectFile(files: FileList) {
    const selectedFile = files.item(0);
    if (!selectedFile) {
      return;
    }
    const file = selectedFile;
    const {uid} = this.fs.currentAppUser;
    const timestamp = (new Date()).getTime();
    const fileName = `${uid}-${timestamp}-${file.name}`;
    const doc: RoadMateFile = {
      addedAt: moment().local().format(RoadMateTimeZone.dateTimeFormat),
      name: fileName,
      size: file.size,
      type: file.type,
      uploadedBy: this.fs.currentAppUser.email,
      url: ''
    };
    this.loading = true;
    try {
      const [transaction] = this.transaction.list;
      const paymentID = transaction.paymentId;
      const date = transaction.authorizationIssuerTime.substring(0, 7);
      doc.subFolder = date;
      
      doc.url = await this.uploadService.startUploadFromWeb(file, fileName, fileStoringWorkflows.invoice);
      if (!doc.url) {
        this.toast.showGenericError('Cette facture n\'a pas pu être associée à cette transaction');
        return;
      }
      this.loading = false;
      this.resizingInProgress = true;
      const res = await this.ff.saveInvoiceRefrence(doc, paymentID);
      if (res && !res.error && res.message === error_messages.OK) {
        this.toast.showSuccessToast('La facture a bien été enregistrée');
        // const [firstTransaction] = this.transaction.list;
        await this.getEmployeeInvoice();
        // if (!firstTransaction.hasInvoices) {
        //   firstTransaction.hasInvoices = true;
        //   if (this.invoices?.files?.length) {
        //     this.invoices.files = this.invoices.files.concat([doc]);
        //   } else {
            
        //   }
        // }
        return;
      }
      this.toast.showGenericError('Cette facture n\'a pas pu être associée à cette transaction');
    } catch (error) {
        console.error(error);
    } finally {
      this.loading = false;
      this.resizingInProgress = false;
    }
  }

  public addFile() {
    const evt = document.createEvent('MouseEvents');
    evt.initEvent('click', true, false);
    this.fileInput.nativeElement.dispatchEvent(evt);
    return;
  }

  public done () {
    this.close();
  }
}
