<c-tabs [activeItemKey]="'messages'" (activeItemKeyChange)="setTab($any($event))">
  <c-tabs-list variant="underline-border">
    <button cTab [itemKey]="'messages'">{{'messages' | translate}}</button>
    <button cTab [itemKey]="'situation'">{{'user_situation' | translate}}</button>
    <button cTab [itemKey]="'viewCardTransactions'">{{'viewCardTransactions' | translate}}</button>
  </c-tabs-list>
  <c-tabs-content>
    <c-tab-panel class="p-3" [itemKey]="'messages'" [transition]="false">
      <c-row>
        <c-col sm="12">
          <h5>
            Catégorie: {{conversation.category | translate}}
          </h5>
          <p *ngIf="conversation.role">
            <c-badge color="primary">
              {{conversation.role | translate}}
            </c-badge>
          </p>
          <p>
            <c-badge color="primary" *ngIf="conversation.createdByDevice">
              {{conversation.createdByDevice | translate}}
            </c-badge>
            <c-badge color="primary" *ngIf="conversation.deviceType && conversation.deviceType !== conversation.createdByDevice">
              {{conversation.deviceType | translate}}
            </c-badge>
            <c-badge color="primary" *ngIf="conversation.createdByVersion">
              {{conversation.createdByVersion | translate}}
            </c-badge>
            <c-badge color="primary" *ngIf="conversation.version && conversation.version !== conversation.createdByVersion">
              {{conversation.version | translate}}
            </c-badge>
          </p>
        </c-col>
      </c-row>
      <div class="convo">
        <div class="row right">
          <div class="bubble">
            <div class="bubble-text" [attr.data-time]="conversation.createdAt ?? '' | rm_iso_to_french_datetime">
              {{conversation.subject}}
            </div>
          </div>
        </div>
        <ng-container *ngIf="messages | async as convoMessages">
          <ng-container *ngIf="convoMessages.length">
            <div class="row" *ngFor="let item of convoMessages | rm_array_sort:'createdAt':'asc'" [ngClass]="{
              'right': item.sender === 'user',
              'left': item.sender === 'agent'
            }">
              <div 
                class="bubble rm-clickable" 
                [ngClass]="{
                  'read': item.isRead,
                  'reaction-nok': item.reaction === 'support_nok_reaction',
                  'reaction-ok': item.reaction === 'support_ok_reaction',
                  'reaction-thinking': item.reaction === 'support_explain_reaction'
                }"
                (click)="selectMessage(item)"
              >
                <div *ngIf="item.message" class="bubble-text" [attr.data-time]="item.createdAt | rm_iso_to_french_datetime">
                  <strong *ngIf="item.sender === 'agent' && item.email">
                    {{item.email | rm_email_to_name}} :
                  </strong>
                  <div [innerHTML]="item.message"></div>
                </div>
                <span style="text-decoration: underline;" *ngIf="!item.message && item.file?.url">
                  {{'ATTECHED_FILE' | translate}}
                </span>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="row ai" *ngIf="aiSuggestion">
          <div class="bubble rm-clickable">
            <div class="bubble-text" [innerHTML]="aiSuggestion"></div>
          </div>
        </div>
        <div class="row ai" *ngIf="aiSuggestion">
          <div class="btns">
            <button cButton size="sm" color="primary" variant="ghost" *ngIf="aiSuggestion" (click)="acceptAiSuggestion()">
              {{'accept_suggestion' | translate}}
            </button>
            <button cButton size="sm" color="warning" variant="ghost" *ngIf="aiSuggestion" (click)="editSuggestion()">
              {{'edit' | translate}}
            </button>
            <button cButton size="sm" color="danger" variant="ghost" *ngIf="aiSuggestion" (click)="aiSuggestion = ''">
              {{'reject_suggestion' | translate}}
            </button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="conversation && conversation.status !== 'resolved'">
        <div class="col-12">
          <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
            <ngx-editor [editor]="editor" [(ngModel)]="messageText" [disabled]="false" aria-label="Message à envoyer" cFormControl placeholder="{{'TYPE_MESSAGE' | translate}}"></ngx-editor>
          </div>
        </div>
        <div class="col-12 rm-row end">
          <div style="width: 120px; margin-right: 15px;">
            <c-input-group class="">
              <!-- <input aria-label="Message à envoyer" cFormControl placeholder="{{'TYPE_MESSAGE' | translate}}" [(ngModel)]="messageText" (keyup.enter)="sendMessage()"/> -->
              <c-dropdown alignment="end">
                <button cButton color="primary" variant="outline" (click)="sendMessage()" [disabled]="askingAi">
                  {{'send' | translate}}
                </button>
                <button [split]="true" cButton cDropdownToggle color="secondary" variant="outline">
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <div cDropdownMenu>
                  <div cDropdownItem  (click)="askAi(llms.gemini)" class="rm-row dispatch-away rm-clickable">
                    <label>
                      Improve with Gemini
                    </label>
                    <img *ngIf="!askingAi" src="/assets/svg/gemini.svg" alt="Gemeni" style="width: 25px; height: 25px;">
                    <c-spinner *ngIf="askingAi" aria-hidden="true" size="sm" color="info" variant="grow"></c-spinner>
                  </div>
                  <div cDropdownItem  (click)="askAi(llms.openai)" class="rm-row dispatch-away rm-clickable">
                    <label>
                      Improve with OpenAI
                    </label>
                    <img *ngIf="!askingAi" src="/assets/svg/chatgpt.svg" alt="OpenAI" style="width: 25px; height: 25px;">
                    <c-spinner *ngIf="askingAi" aria-hidden="true" size="sm" color="info" variant="grow"></c-spinner>
                  </div>
                  <div cDropdownItem  (click)="askAi(llms.mistral)" class="rm-row dispatch-away rm-clickable">
                    <label>
                    Improve with Mistral
                    </label>
                    <img *ngIf="!askingAi" src="/assets/svg/mistral.svg" alt="Claude" style="width: 25px; height: 25px;">
                    <c-spinner *ngIf="askingAi" aria-hidden="true" size="sm" color="info" variant="grow"></c-spinner>
                  </div>
                  <hr cDropdownDivider>
                  <div cDropdownItem (click)="suggestResponse()"  class="rm-row dispatch-away rm-clickable">
                    <span *ngIf="!messageText">
                      Suggest response
                    </span>
                    <c-spinner *ngIf="askingAi" aria-hidden="true" size="sm" color="info" variant="grow"></c-spinner>
                  </div>
                </div>
              </c-dropdown>
            </c-input-group>
          </div>
          <div class="right">
            <button cButton color="danger" variant="outline" (click)="closeConversation()" [disabled]="askingAi">
              {{'close_ticket'|translate}}
            </button>
          </div>
        </div>
        <div class="col-12 rm-row end">
          <rm-upload-input
            *ngIf="agentRef && companyRef"
            (urlReadyEmitter)="createMessageFromFile($event)"
            [workflow]="fileType"
            [companyRef]="companyRef"
            [agentRef]="agentRef"
          ></rm-upload-input>
          
        </div>
      </div>
    </c-tab-panel>
    <c-tab-panel class="p-3" [itemKey]="'situation'" [transition]="false">
      <c-progress variant="striped" *ngIf="loading" [value]="100" color="primary" animated class="mb-3"/>
      <rm-user-situation
        *ngIf="userSituation"
        [situation]="userSituation"
        [email]="conversation.email"
        [conversationInHand]="conversation"
        (closeEmitter)="close($event)"
      >
      </rm-user-situation>
    </c-tab-panel>
    <c-tab-panel class="p-3" [itemKey]="'viewCardTransactions'" [transition]="false">
      <rm-transactions-viewer
        *ngIf="conversation"
        [agentRef]="conversation.agentRef"
        [companyRef]="conversation.companyRef"
        [email]="conversation.email"
      >
      </rm-transactions-viewer>
    </c-tab-panel>
  </c-tabs-content>
</c-tabs>

