import { Component, Input, OnInit } from '@angular/core';
import { ButtonDirective } from '@coreui/angular';
import { cilLink, cilReload } from '@coreui/icons';
import { IconDirective } from '@coreui/icons-angular';
import { TranslateModule } from '@ngx-translate/core';
import { RoadMateFile } from '@roadmate/roadmate-common';
import { UrlSanitizerPipe } from '../pipes';

@Component({
  selector: 'rm-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
  standalone: true,
  imports: [
    IconDirective, TranslateModule, ButtonDirective, UrlSanitizerPipe
  ]
})
export class FileViewerComponent implements OnInit {
  public fileType: 'pdf' | 'image' | 'none' = 'none';
  @Input() public file: RoadMateFile;
  icons = {
    cilReload,
    cilLink
  }
  constructor() { }

  ngOnInit(): void {
    if (this.file) {
      if (this.file.type.includes('image') || 
        this.file.type.includes('jpg') ||
        this.file.type.includes('png') ||
        this.file.type.includes('svg') ||
        this.file.type.includes('jpeg')
      ) {
        this.fileType = 'image';
        return;
      }
      this.fileType = 'pdf';
    }
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }
}
