@if(hasNoCard) {
  <c-row>
    <c-col sm="12">
      <p>
        {{targetObject.firstname}} {{targetObject.lastname}} {{'has_no_card_yet' | translate}}
      </p>
    </c-col>
    <c-col sm="12" md="6">
      <button *ngIf="isSuperAdmin" class="btn btn-primary" type="button" (click)="orderNewCard()">
        {{'order_new_card' | translate}}
      </button>
    </c-col>
  </c-row>
} @else {
  <c-row>
    <c-col sm="12" md="4">
      <div class="centered-container">
        <rm-flip-card [expiryDate]="'XX/XX'" [lastFourDigits]="'XXXX'"
          [name]="targetObject.firstname + ' ' + targetObject.lastname" [cvc]="'XXX'">
        </rm-flip-card>
      </div>
    </c-col>
    <c-col sm="12" md="8">
      <ul cListGroup>
        <li cListGroupItem>
          <c-row>
            <c-col sm="12" md="6">
              <strong>
                {{'card_has_been_sent_status' | translate}}
              </strong>
            </c-col>
            <c-col sm="12" md="6">
              <strong *ngIf="!card.trackingCodeSent">
                {{'no' | translate}}
              </strong>
              <a [href]="'https://www.laposte.fr/outils/suivre-vos-envois?code='+card.shippingTrackingCode" target="_blank" *ngIf="card.shippingTrackingCode">
                {{'yes' | translate}}
              </a>
            </c-col>
          </c-row>
        </li>
        <li cListGroupItem>
          <c-row>
            <c-col sm="12" md="6">
              <strong>
                {{'card_has_been_activated_status' | translate}}
              </strong>
            </c-col>
            <c-col sm="12" md="6">
              <strong *ngIf="!card.isLive">
                {{'no' | translate}}
              </strong>
              <strong *ngIf="card.isLive">
                {{'yes' | translate}}
              </strong>
            </c-col>
          </c-row>
        </li>
        <li cListGroupItem>
          <c-row>
            <c-col sm="12" md="6">
              <strong>
                {{'card_has_been_set_password_status' | translate}}
              </strong>
            </c-col>
            <c-col sm="12" md="6">
              <strong *ngIf="!card.hasSetupPassword">
                {{'no' | translate}}
              </strong>
              <strong *ngIf="card.hasSetupPassword">
                {{'yes' | translate}}
              </strong>
            </c-col>
          </c-row>
        </li>
        <li cListGroupItem>
          <c-row>
            <c-col sm="12" md="6">
              <strong>
                {{'card_is_operational' | translate}}
              </strong>
            </c-col>
            <c-col sm="12" md="6">
              <strong *ngIf="card.lockStatus !== 'UNLOCK'" class="red">
                {{card.lockStatus | translate}}
              </strong>
              <strong *ngIf="card.lockStatus === 'UNLOCK'">
                {{'yes' | translate}}
              </strong>
            </c-col>
          </c-row>
        </li>
        <li cListGroupItem>
          <c-row>
            <c-col sm="12" md="6">
              <button 
                class="btn" 
                [ngClass]="{'btn-primary': card.lockStatus === 'LOCK', 'btn-danger': card.lockStatus === 'UNLOCK'}"
                type="button" 
                (click)="lockCard(card.lockStatus === 'UNLOCK')">
                <span *ngIf="card.lockStatus === 'UNLOCK'">
                  {{'lock_card_btn' | translate}}
                </span>
                <span *ngIf="card.lockStatus === 'LOCK'">
                  {{'unlock_card_btn' | translate}}
                </span>
              </button>
            </c-col>
            <c-col sm="12" md="6">
              
            </c-col>
          </c-row>
        </li>
        <li *ngIf="isSuperAdmin" cListGroupItem>
          <c-row>
            <c-col sm="12" md="6">
              <button 
                class="btn btn-danger" 
                type="button"
                (click)="destroyCard()"
              >
                Destroy card
              </button>
            </c-col>
            <c-col sm="12" md="6">
              
            </c-col>
          </c-row>
        </li>
      </ul>
    </c-col>
  </c-row>
}

