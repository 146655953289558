<div class="rm-column main-display fade-in">
  <c-progress variant="striped" [value]="100" animated class="mb-3" color="primary"  *ngIf="!ordersLoaded"></c-progress>
  <div class="row detached push-down" *ngIf="!selectedGroup">
    <div class="col-6">
      <div class="form-group">
        <label>
          {{'select_group' | translate}} 
          {{'in_order_to_see_which_employee_is_in_which_group'|translate}}
        </label>
        <rm-grouped-multi-select
          [groups]="groups"
          [selectedItems]="[]"
          [allowMultiple]="false"
          [placeholder]="'group_assiciated'"
          (changeEmitter)="updateUserGroups($event)"
        >
        </rm-grouped-multi-select>
      </div>
    </div>
  </div>
  <div class="row detached" *ngIf="!selectedGroup">
    <div class="col-12">
      <p>
        {{'please_select_one_group' | translate}} 
      </p>
    </div>
  </div>
  <div class="row detached" *ngIf="selectedGroup && users?.length === 0">
    <div class="col-12">
      <p>
        {{'no_users_in_this_group' | translate}} 
      </p>
    </div>
  </div>
  <div class="rm-column rm-simple-table" *ngIf="users && users.length">
    <p>
      {{users.length}} {{'users_in_this_group' | translate}} 
    </p>
    <div class="row dispatch-away rm-line">
      <div class="col-3"></div>
      <div class="col-4"></div>
      <div class="col-4"></div>
      <div class="col-1 right"></div>
    </div>
    <div class="row dispatch-away rm-line" *ngFor="let user of users; let i = index">
      <div class="col-3">
        <strong>
        {{user.label}}
        </strong>
      </div>
      <div class="col-4">
        <a href="mailto:{{user.value}}" >
          {{user.value}}
        </a>
      </div>
      <div class="col-4 clickable">
        <span class="rm-tag" *ngIf="selectedGroup">
          {{selectedGroup.label}}
        </span>
      </div>
      <div class="col-1 right">
        <button
          *ngIf="savingIndex === i"
          [disabled]="savingIndex === i"
          cLoadingButton
          color="info"
          spinnerType="grow"
          type="button"
          [disabled]="savingIndex === i"
        >
          {{'saving' | translate}}
        </button>
        
      </div>
    </div>
  </div>
</div>
