<div class="toast-container">
  <!-- <c-toaster placement="top-end" position="absolute"> -->
    <c-toast 
      *ngFor="let toast of toasts"
      [autohide]="true" 
      [fade]="true" 
      [visible]="toast.isVisible"
      [color]="toast.mode"
      class="push-down"
    >
      <c-toast-header [closeButton]="false">
        {{(toast.title ?? 'Notification')| translate}}
        <button aria-label="close" style="margin-right:0!important" cButtonClose black class="me-2 m-auto" (click)="close(toast)"></button>
      </c-toast-header>
      <c-toast-body>
        <p [innerHTML]="(toast.message ?? '') | translate"></p>
      </c-toast-body>
    </c-toast>
  <!-- </c-toaster> -->
</div>

