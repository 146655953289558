import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColComponent, ListGroupModule, RowComponent } from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoles, Treezor } from '@roadmate/roadmate-common';
import { FireAuthService } from '@rm-services';

export enum userAccessLevelActions {
  SUSPEND = 'suspend',
  REACTIVATE = 'reactivate',
  DELETE = 'delete',
  MAKE_ADMIN = 'make_admin',
  REMOVE_ADMIN = 'remove_admin',
  MAKE_ACCOUNT_MANAGER = 'make_account_manager',
  REMOVE_ACCOUNT_MANAGER = 'remove_account_manager',
  MAKE_EMPLOYEE = 'make_employee'
}

@Component({
  selector: 'rm-user-access-level',
  templateUrl: './user-access-level.component.html',
  standalone: true,
  imports: [
    TranslateModule, NgIf, RowComponent, ColComponent, ListGroupModule
  ]
})
export class UserAccessLevelComponent implements OnInit {
  @Input({required: true})
  public targetObject: Treezor.AppUser;
  @Input({required: true})
  public userACL: AppRoles;
  
  @Input({required: true})
  public suspended = false;
  @Output()
  public actionEmitter = new EventEmitter<userAccessLevelActions>();

  public isSelf = false;
  public isAccountManager = false;
  public isAdmin = false;
  public isAgent = false;
  public isSuperAdmin = false;
  constructor(
    private auth: FireAuthService
  ) {
    this.isSuperAdmin = this.auth.isSuperAdmin;
    this.isAdmin = this.auth.isAdmin;
    this.isAgent = this.auth.isAgent;
    this.isAccountManager = this.auth.isAccountManager;
  }

  ngOnInit(): void {
    this.isSelf = this.auth.user.email === this.targetObject.email;
  }

  public suspendUser() {
    if (!this.suspended) {
      this.actionEmitter.emit(userAccessLevelActions.SUSPEND);
    } else {
      this.actionEmitter.emit(userAccessLevelActions.REACTIVATE);
    }
  }

  public deleteUser() {
    this.actionEmitter.emit(userAccessLevelActions.DELETE);
  }

  public makeAdmin() {
    if (!this.isAdmin && !this.isSuperAdmin) {
      return;
    }
    if (!this.userACL.admin) {
      this.actionEmitter.emit(userAccessLevelActions.MAKE_ADMIN);
    } else {
      this.actionEmitter.emit(userAccessLevelActions.REMOVE_ADMIN);
    }
  }

  public makeEmployee() {
    if (!this.isAdmin && !this.isSuperAdmin) {
      return;
    }
    if (!this.userACL.employee && !this.userACL.accountmanager) {
      this.actionEmitter.emit(userAccessLevelActions.MAKE_EMPLOYEE);
    }
  }

  public makeAccountManager() {
    if (!this.isAccountManager && !this.isSuperAdmin) {
      return;
    }
    if (!this.userACL.accountmanager) {
      this.actionEmitter.emit(userAccessLevelActions.MAKE_ACCOUNT_MANAGER);
    } else {
      this.actionEmitter.emit(userAccessLevelActions.REMOVE_ACCOUNT_MANAGER);
    }
  }
}
