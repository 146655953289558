import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FireStoreService, PdfPrinterService, RMToasterService, UploadFileService } from '@rm-services';
import { fileStoringWorkflows } from '@roadmate/roadmate-common';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ColComponent, RowComponent } from '@coreui/angular';
import { SignaturePadModule, SignaturePad } from '@vijhhh2/ngx-signaturepad';

@Component({
  selector: 'rm-attestation',
  templateUrl: './attestation.component.html',
  styleUrls: ['./attestation.component.scss'],
  standalone: true,
  imports: [
    NgIf, TranslateModule, RowComponent, ColComponent, SignaturePadModule
  ]
})
export class AttestationComponent implements OnInit {
  @Input()
  htmlText: string;
  @Input()
  beforSignatureText: string;
  @Input()
  pdfText: string;
  @Input()
  attestationObject: string;
  @Input()
  attestationType = 'ALL';
  @Input()
  isIKV = false;
  @Output()
  public urlReadyEmitter: EventEmitter<string> = new EventEmitter<string>();
  public loading = false;
  // public signaturePad: SignaturePadNative;
  public dataSignature: string;
  private counter = 0;
  @ViewChild('myCanvas', {static: false}) canvasEl: ElementRef<HTMLCanvasElement>;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  styles: Partial<CSSStyleDeclaration> = {
    border: '1px dashed rgb(192, 192, 192)',
    cursor: 'crosshair',
    width: '100%',
    minWidth: '100%',
    display: 'block',
  };
  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 0.5,
    'maxWidth': 2.5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  constructor(
    private fs: FireStoreService,
    private elementRef: ElementRef,
    private pdfMaker: PdfPrinterService,
    private uploader: UploadFileService,
    // private ff: FireFunctionsService,
    private toast: RMToasterService,
    private chg: ChangeDetectorRef,
  ) { }

  ngOnInit(): void { }

  public ngAfterContentInit(): void {
    if (!this.signaturePad) {
        if (this.counter > 3) {
          // this.toast.showGenericError(`Une erreur s'est produite lors de la préparation de la zone de signature. Veuillez imprimer l'attestation, la signer et nous l'envoyer.`);
          return;
        }
        setTimeout(() => {
          this.ngAfterContentInit();
        }, 1500);
        this.counter++;
        return;
    }
    
    this.signaturePad.set('minWidth', 0.5); // set szimek/signature_pad options at runtime
    this.signaturePad.set('maxWidth', 2.5); // set szimek/signature_pad options at runtime
    // this.signaturePad.on = this.onBegin.bind(this);
    // this.signaturePad.off = this.onEnd.bind(this);
    this.signaturePad.clear();
  }

  drawEnd() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
    this.dataSignature = this.signaturePad.toDataURL();
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  public onBegin() {
    // console.log('start drawing');
  }

  public onEnd() {
    this.dataSignature = this.signaturePad.toDataURL();
  }

  public startDrawing(e: any) {
    // console.log('start drawing', e);
  }

  public moved(e: any) {
    // console.log('moved', e);
  }

  public removeSignature() {
    this.signaturePad.clear();
    this.dataSignature = '';
    this.chg.detectChanges();
  }

  public async saveAttestation() {
    this.loading = true;
    try {
      this.dataSignature = this.signaturePad.toDataURL();
      const pdfBase64 = await this.pdfMaker.generatePdf(
        this.pdfText, this.beforSignatureText, this.dataSignature, this.isIKV
      );
      const year = (new Date()).getFullYear();
      const timestamp = (new Date()).getTime();
      const url = await this.uploader.uploadBase64File(
      fileStoringWorkflows.attestations,
      `${fileStoringWorkflows.attestations}-${this.attestationType}-${year}-[${this.fs.currentAppUser.email}]-[${timestamp}].pdf`,
      `${pdfBase64}`);
      this.urlReadyEmitter.emit(url);
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError('Une erreur s\'est produite lors de la génération de l\'attestation. Veuillez réessayer plus tard.');
    } finally {
      this.loading = false;
    }
  }
}
