<c-modal
  [keyboard]="false"
  [transition]="true"
  [visible]="true"
  scrollable
  alignment="top"
  
  backdrop="static"
  [fullscreen]="size === 'large'"
>
  <c-modal-header>
    <h5 cModalTitle>
      {{modalTitle | translate}} <span class="bolder" *ngIf="headerSuffix">{{headerSuffix}}</span>
      <span class="rm-clickable" (click)="copyValue(headerSuffix)" *ngIf="headerSuffix">
        <svg [cIcon]="icons.cilCopy" size="lg" title="copier"></svg>
      </span>
    </h5>
    <button cButtonClose (click)="close(false)"></button>
  </c-modal-header>
  <c-modal-body>
    <c-progress variant="striped" *ngIf="!dynamicComponent" color="primary" class="mb-3" />
    @if(dynamicComponent) {
      <ng-container *ngComponentOutlet="dynamicComponent; inputs: {data, id: modalId};" (closeModal)="close(false)" />
    }
  </c-modal-body>
  <c-modal-footer  *ngIf="!dismissFooter">
    <!-- <button cButton color="secondary">Close</button>
    <button cButton color="primary">Save changes</button> -->
    <button cButton color="secondary" (click)="close(false)">
      {{'cancel' | translate}}
    </button>
    <button *ngIf="actionlbl" color="primary"  (click)="close(true)" cButton>
      <span>
        {{actionlbl | translate}}
      </span>
    </button>
  </c-modal-footer>
</c-modal>
<!-- <div class="modal-dialog"
  [ngClass]="{
    'modal-warning': mode === 'warning',
    'modal-success': mode === 'success',
    'modal-primary': mode === 'primary',
    'modal-danger': mode === 'danger',
    'regular': size === 'regular',
    'small': size === 'small'
  }"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{modalTitle | translate}} <span *ngIf="headerSuffix">({{headerSuffix}})</span></h4>
      <button class="rm-close-white" type="button" (click)="close(false)">
        <i class="cil-x-circle"></i>
      </button>
    </div>
    <div class="card-body">
      <ng-template modal-holder></ng-template>
    </div>
    <div class="modal-footer" *ngIf="!dismissFooter">
      <button class="btn btn-secondary" type="button" (click)="close(false)">
        {{'cancel' | translate}}
      </button>
      <button *ngIf="actionlbl" class="btn btn-{{mode}}" type="button"  (click)="close(actionlbl)">
        <span>
          {{actionlbl | translate}}
        </span>
      </button>
    </div>
  </div>
</div> -->

