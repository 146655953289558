import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appRoutes, idleValues } from '@rm-static';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { TranslateModule } from '@ngx-translate/core';
import { ModalService } from '@rm-services';

@Component({
  selector: 'rm-logout-warning',
  templateUrl: './logout-warning.component.html',
  styleUrls: ['./logout-warning.component.scss'],
  standalone: true,
  imports: [
    TranslateModule
  ]
})
export class LogoutWarningComponent extends AbstractEntryComponent<boolean> implements OnInit, OnDestroy {
  public remaingTime = idleValues.countDown;
  public intervallId: any;
  constructor(
    private route: Router,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.intervallId = setInterval(() => {
      if (this.remaingTime > 0) {
        this.remaingTime--;
      } else {
        this.logout(true);
      }
    }, 1000);
  }

  public logout(doIt: boolean) {
    if (doIt) {
      this.route.navigateByUrl(`/${appRoutes.logout}`);
    }
    this.close(doIt);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    clearInterval(this.intervallId);
  }
}
