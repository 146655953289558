import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FireStoreService } from '@rm-services';
import { Kyb, Treezor } from '@roadmate/roadmate-common';
import { ProgressComponent, RowComponent, ColComponent, CardModule } from '@coreui/angular';
import { FilterArrayPipe, TruncPipe } from '@rm-modules/pipes';


@Component({
  selector: 'rm-view-uploaded-documents',
  templateUrl: './view-uploaded-documents.component.html',
  standalone: true,
  imports: [
    NgFor, NgIf, TranslateModule, RowComponent, ColComponent, ProgressComponent, ColComponent, CardModule,
    FilterArrayPipe, TruncPipe
  ]
})
export class ViewUploadedDocumentsComponent implements OnInit {
  public treezorUploadedDocuments: Treezor.Document.Definition[];
  @Input(
    {
      required: true
    }
  )
  kyb: Kyb;
  constructor(
    private fs: FireStoreService,
  ) { }

  ngOnInit(): void {
    this.getDocuments();
  }

  private async getDocuments () {
    const { ref, agentRef } = this.kyb;
    if (!ref || !agentRef) {
      throw new Error('Il manque la ref ou l\'agent ref');
      return;
    }
    this.treezorUploadedDocuments = await this.fs.getUploadedKybDocuments(agentRef, ref);
  }
}
