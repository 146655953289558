import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColComponent, ListGroupModule, ProgressBarComponent, ProgressComponent, RowComponent, TextColorDirective } from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownListOption, ExpenseLine, ExpenseLineStatus, GroupedDDL, RoadMateOrders, Treezor } from '@roadmate/roadmate-common';
import { FireFunctionsService, FireStoreService, RMToasterService } from '@rm-services';
import { RMMultiSelectModule } from '@rm-modules/multi-select/multi-select.module';
import { ExpenseLineStatusComponent } from '@rm-modules/expenseline-status/expenseline-status.component';
import { ExpenseLineStatusColorPipe } from '@rm-modules/pipes';

@Component({
  selector: 'rm-user-groups-budget',
  templateUrl: './user-groups-budget.component.html',
  standalone: true,
  imports: [
    TranslateModule, NgIf, RowComponent, ColComponent, ListGroupModule, ProgressComponent, ProgressBarComponent, TextColorDirective,
    NgClass, RMMultiSelectModule, NgFor, NgStyle, ExpenseLineStatusComponent, ExpenseLineStatusColorPipe
  ]
})
export class UserGroupsAndBudgetsComponent implements OnInit {
  public loading = true;
  public groupSelectionDirty = false;
  public savingLoader = false;
  public ordersLoaded = false;
  public orders: RoadMateOrders[] = [];
  public ordersDDL: DropDownListOption[] = [];
  public agentRef = '';
  public companyRef = '';
  private selectedGroups: GroupedDDL[] = [];
  @Output()
  public actionsEmitter = new EventEmitter<string>();
  @Input({
    required: true
  })
  public expenseLines: ExpenseLine[];
  public savedSuccess = '';
  public groups: GroupedDDL[];
  public initialOrders: DropDownListOption[] = [];
  public initialGroups: DropDownListOption[] = [];
  @Input({
    required: true
  })
  public targetObject: Treezor.AppUser;
  public stats = ExpenseLineStatus;
  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private toast: RMToasterService
  ) { }

  ngOnInit(): void {
    this.agentRef = this.targetObject.agentRef;
    this.companyRef = this.targetObject.companyRef;
    if (!this.agentRef && !this.companyRef) {
      throw new Error('agentRef or companyRef is missing');
    }
    this.getOrders ();
  }

  private initOrders () {
    this.initialOrders = this.getOrdersFromObject(this.targetObject?.userGroupIds ?? []);
    this.initialGroups = this.getGroupsFromObject(this.targetObject?.userGroupIds ?? []);
    this.selectedGroups = JSON.parse(JSON.stringify(this.targetObject?.userGroupIds ?? []));
  }

  private getGroupsFromObject (userGroupIds: GroupedDDL[]) {
    const groups: DropDownListOption[] = [];
    userGroupIds.forEach(order => {
      order.ddl?.forEach(group => groups.push({
        ...group,
        parentValue: order.value,
        createdAt: group.createdAt ?? (new Date()).toISOString(),
        byBatch: false
      }))
    });
    return groups.sort(
      (a, b) => {
        return a.value < b.value ? -1 : 1
      }
    );
  }

  public async saveGroups() {
    this.savingLoader = true;
    try {
      const newSelectedOrders = this.getOrdersFromObject(this.selectedGroups);
      const newSelectedGroups = this.getGroupsFromObject(this.selectedGroups);
      const addedOrders = newSelectedOrders.filter(el => this.initialOrders.every(order => order.value !== el.value));
      const deletedOrders = this.initialOrders.filter(el => newSelectedOrders.every(order => order.value !== el.value));

      const addedGroups = newSelectedGroups.filter(el => this.initialGroups.every(group => group.value !== el.value));
      const deletedGroups = this.initialGroups.filter(el => newSelectedGroups.every(group => group.value !== el.value));
      const {agentRef, companyRef} = this.targetObject;
      if (!agentRef || !companyRef) {
        this.toast.showGenericError();
        return;
      }
      if (deletedOrders.length) {
        await this.fs.removeUserFromOrders(agentRef, companyRef, deletedOrders, this.targetObject.email);
      }
      if (deletedGroups.length) {
        await this.fs.removeUserFromGroups(agentRef, companyRef, deletedGroups, this.targetObject.email);
      }

      if (addedOrders.length) {
        await this.fs.addUserToOrders(agentRef, companyRef, addedOrders, this.targetObject.email);
      }
      if (addedGroups.length) {
        await this.fs.addGroupToUsers(agentRef, companyRef, addedGroups, this.targetObject.email);
      }
      
      this.targetObject.userGroupIds = this.selectedGroups;
      this.fs.updateAppUser(this.targetObject);
      this.savedSuccess = 'saved_success';
    } catch (e) {
      console.error('', e);
    } finally {
      this.savingLoader = false;
      // this.ref.detectChanges();
    }
  }


  private async getOrders () {
    try {
      this.loading = true;
      this.ordersLoaded = false;
      this.orders = await this.fs.getClientOrders(this.agentRef, this.companyRef);
      this.ordersDDL = this.orders.map(el => {
        return {
          value: el.ref ??'',
          label: el.product.name
        }
      });
      this.groups = this.orders.map(el => {
        return {
          value: el.ref ?? '',
          label: el.product.name,
          ddl: el.beneficiaryGroup ?
          el.beneficiaryGroup.map(item => {
            return {
              value: item.value,
              label: item.label
            }
          }) : []
        }
      });
      this.initOrders();
    } catch (e) {
      console.error('', e);
    } finally {
      this.ordersLoaded = true;
      this.loading = false;
      // this.ref.detectChanges();
    }
  }

  public updateUserGroups(selectedGroups: GroupedDDL[]) {
    this.savedSuccess = '';
    this.selectedGroups = selectedGroups;
    this.groupSelectionDirty = true;
    // this.ref.detectChanges();
    // this.targetObject.userGroupIds = selectedGroups;
  }

  public async suspendExpenseLine(expense: ExpenseLine, suspend = true) {
    this.loading = true;
    try {
      await this.ff.suspendExpenseLine(expense, suspend, this.targetObject.email, this.targetObject.companyRef);
      this.actionsEmitter.emit('refresh_situation');
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private getOrdersFromObject(input: GroupedDDL[]) {
    return input.map(el => {
      return {
        value: el.value,
        label: el.label,
        byBatch: false
      }
    }).sort(
      (a, b) => {
        return a.value < b.value ? -1 : 1
      }
    );
  }


}
