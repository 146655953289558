import { Component, Input, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { Treezor } from '@roadmate/roadmate-common';
import { NgIf } from '@angular/common';
import {UserRefundsAndTransactionsComponent} from '../user-refunds-transactions/user-refunds-transactions.component';
import { ModalService } from '@rm-services';

@Component({
  selector: 'rm-deleted-user-modal',
  template: `
    <rm-user-refunds-transactions *ngIf="targetObject" [appUser]="targetObject"></rm-user-refunds-transactions>
  `,
  standalone: true,
  imports: [NgIf, UserRefundsAndTransactionsComponent]
})
export class DeleteUserDetailsComponent extends AbstractEntryComponent<any> implements OnInit {
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.targetObject = value.targetObject;
  }
  public targetObject: Treezor.AppUser;
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void { }
}
