<!--sidebar-->
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed border-end"
  colorScheme="light"
  id="sidebar1"
  visible
  [unfoldable]="true"
>
  <c-sidebar-header class="border-bottom">
    <c-sidebar-brand 
      [routerLink]="['/']"
      [brandFull]="selectedLogos.rectangle"
      [brandNarrow]="selectedLogos.square"
    >
    </c-sidebar-brand>
  </c-sidebar-header>

  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)" class="overflow" pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="navItems" dropdownMode="close" [compact]="false" />
  </ng-scrollbar>
  @if (!sidebar1.narrow) {
    <c-sidebar-footer cSidebarToggle="sidebar1" class="border-top d-none d-lg-flex">
      <!-- <div class="logout-action rm-clickable" (click)="logout()">
        <svg [cIcon]="icons.cilAccountLogout" size="lg" title="Déconnexion"></svg> 
        <label class="label-action">
          {{'logout'|translate}}
        </label>
      </div> 
      <div class="logout-icon rm-clickable">
        <svg [cIcon]="icons.cilAccountLogout" size="lg" title="Déconnexion"></svg>
      </div> -->
      <div class="logout-action rm-clickable" (click)="logout()">
        <svg [cIcon]="icons.cilAccountLogout" size="lg" title="Déconnexion"></svg> 
        <label class="label-action rm-clickable">
          {{'logout'|translate}}
        </label>
      </div> 
      <!-- <div class="logout-icon rm-clickable">
        <svg [cIcon]="icons.cilAccountLogout" size="lg" title="Déconnexion"></svg>
      </div> -->
    </c-sidebar-footer>
  }
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100">
  <!--app-header-->
  <app-default-header
    [cShadowOnScroll]="'sm'"
    class="mb-4 d-print-none header header-sticky p-0 shadow-sm"
    position="sticky"
    sidebarId="sidebar1"
  />
  <!--app-body-->
  <div class="body flex-grow-1">
    <c-container breakpoint="lg" class="h-auto px-4">
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer />
</div>
<ng-container *ngIf="parcel | async as parcelObject">
  <c-row>
    <c-col sm="12" [attr.md]="parcelObject.length === 1 ? '12': '6'" *ngFor="let modal of parcelObject">
      <rm-modal
        *ngIf="modal.isOpen"
        class="modal"
        [modalTitle]="modal.modalTitle ?? ''"
        [modalId]="modal.id ?? ''"
        [mode]="modal.mode ?? 'primary'"
        [actionlbl]="modal.actionlbl ?? ''"
        [componentName]="modal.component"
        [dismissFooter]="!!modal.dismissFooter"
        [data]="modal.data"
        [headerSuffix]="modal.headerSuffix ?? ''"
        [size]="modal.size ?? 'regular'"
      >
      </rm-modal>
    </c-col>
  </c-row>
</ng-container>
