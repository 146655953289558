import { Component, Input, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { DropDownListOption, RoadMateFile, SupportConversation, SupportMessage, SupportMessageReactions, companySupportCategories, employeeSupportCotegories, fileStoringWorkflows } from '@roadmate/roadmate-common';
import { FireStoreService, ModalService } from '@rm-services';
import { Observable, Subject } from 'rxjs';
import moment from 'moment';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SortArrayByTimePipe } from '@rm-modules/pipes';
import { FormsModule } from '@angular/forms';
import { UploadInputComponent } from '@rm-modules/upload-input/upload-input.component';
import { RMMultiSelectModule } from '@rm-modules/multi-select/multi-select.module';
import { TooltipDirective } from '@coreui/angular';
import { NgxEditorModule, Editor } from 'ngx-editor';
// import moment from 'moment';

@Component({
  selector: 'rm-employee-conversation',
  templateUrl: './employee-conversation.component.html',
  styleUrls: ['./employee-conversation.component.scss'],
  standalone: true,
  imports: [
    NgIf, TranslateModule, AsyncPipe, NgFor, SortArrayByTimePipe, NgClass, FormsModule, UploadInputComponent,
    RMMultiSelectModule, TooltipDirective, NgxEditorModule
  ]
})
export class EmployeeConversationComponent extends AbstractEntryComponent<boolean> implements OnInit {
  @Input()
  public conversation: SupportConversation;
  @Input()
  role: string;
  public messages: Observable<SupportMessage[]>;
  private allMessages: SupportMessage[] = []; 
  public messageText: string = '';
  public category: string;
  public subject = '';
  public loading = false;
  public cataegories: DropDownListOption[] = [];
  public fileType = fileStoringWorkflows.support;
  public companyRef: string;
  public agentRef: string;
  public selectedMessage: string;
  public currentReaction: SupportMessageReactions;
  public reactions = SupportMessageReactions;
  public editor: Editor;
  override set data(value: any) {
    if (value.role === 'employee') {
      const values: any = {...employeeSupportCotegories};
      this.cataegories = Object.keys(employeeSupportCotegories).map((key: string) => {
        return {
          value: key,
          label: values[key as string]
        };
      });
    } else {
      const values: any = {...companySupportCategories};
      this.cataegories = Object.keys(companySupportCategories).map(key => {
        return {
          value: key,
          label: values[key]
        };
      });
    }
    if (!value || !value.conversation?.ref) {
      return;
    }
    this.conversation = value.conversation;
  }

  constructor(
    private fs: FireStoreService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.agentRef = this.fs.currentAppUser.agentRef;
    this.companyRef = this.fs.currentAppUser.companyRef;
    if (this.conversation?.ref) {
      this.getAllMessages();
    }
  }

  public selectCategory(item: DropDownListOption) {
    this.category = item.value;
  }

  public async createMessageFromFile(data: {doc: RoadMateFile, fieldName: string}) {
    if (!data.doc?.url || !this.fs.currentAppUser.uid || !this.conversation.ref) {
      return;
    }
    const message: SupportMessage = {
      uid: this.fs.currentAppUser.uid,
      conversationRef: this.conversation.ref,
      message: '',
      file: data.doc,
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      sender: 'user',
      reaction: SupportMessageReactions.support_no_reaction,
      email: this.fs.currentAppUser.email
    };
    await this.fs.createSupportMessage(
      this.conversation.uid,
      this.conversation.ref,
      message
    );
    if (!this.conversation.hasAttachedFile) {
      await this.fs.updateConversation(
        this.conversation.uid,
        this.conversation.ref,
        {
          hasAttachedFile: true,
          updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          lastSender: 'user',
          lastMessage: 'File added',
          readByAgent: false,
        }
      );
    }
  }

  public async reactToMessageWith(reaction: SupportMessageReactions) {
    if (!this.selectedMessage && this.allMessages.length && this.conversation.lastSender === 'agent') {
      this.selectedMessage = this.allMessages[this.allMessages.length - 1].ref ?? '';
    }
    if (!this.selectedMessage) {
      return;
    }
    const uid = this.fs.currentAppUser.uid;
    if (!uid || !this.conversation.ref) {
      return;
    }
    await this.fs.reactToSupportMessage(uid, this.conversation.ref, this.selectedMessage, reaction);
    this.selectedMessage = '';
    this.currentReaction = SupportMessageReactions.support_no_reaction;
    await this.fs.updateConversation(
      this.conversation.uid,
      this.conversation.ref,
      {
        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        lastSender: 'user',
        lastMessage: reaction,
        readByAgent: false,
      }
    );
  }

  public selectMessage(message: SupportMessage) {
    if (!message.ref) {
      return;
    }
    if (message?.file?.url) {
      window.open(message.file.url, '_blank');
      return;
    }
    if (this.conversation.status === 'resolved') {
      return;
    }
    if (this.selectedMessage === message.ref) {
      this.selectedMessage = '';
      this.currentReaction = SupportMessageReactions.support_no_reaction;
      return;
    }
    this.selectedMessage = '';
    this.currentReaction = SupportMessageReactions.support_no_reaction;
    if (message.sender === 'user') {
      return;
    }
    this.selectedMessage = message.ref;
    this.currentReaction = message.reaction;
  }

  public async createConversation() {
    if (!this.fs.currentAppUser.uid) {
      console.error('No user uid');
      return;
    }
    this.loading = true;
    try {
      const conv: SupportConversation = {
        uid: this.fs.currentAppUser.uid,
        firstname: this.fs.currentAppUser.firstname,
        lastname: this.fs.currentAppUser.lastname,
        email: this.fs.currentAppUser.email,
        companyRef: this.fs.currentAppUser.companyRef,
        agentRef: this.fs.currentAppUser.agentRef,
        status: 'open',
        subject: this.subject,
        category: this.category,
        createdAt: moment().utc().toISOString(),
        updatedAt: new Date().toISOString(),
        role: this.fs.currentAppUser.role,
        lastSender: 'user',
        lastMessage: this.subject,
        aboutRefundRef: '',
      };
      const ref = await this.fs.createSupportConversation(conv);
      conv.ref = ref;
      this.conversation = conv;
      this.getAllMessages();
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private getAllMessages() {
    if (!this.conversation.uid || !this.conversation.ref) {
      console.error('No user uid');
      return;
    }
    const subject = new Subject<SupportMessage[]>();
    this.messages = subject.asObservable();
    this.subs.push(
      this.messages.subscribe((messages: SupportMessage[]) => {
        this.allMessages = messages;
        if (this.allMessages.length) {
          this.allMessages.sort((a, b) => {
              return (new Date(a.createdAt ?? '')).getTime() - (new Date(b.createdAt ?? '')).getTime();
            }
          );
          const lastMessage = this.allMessages[this.allMessages.length - 1];
          if (lastMessage.sender === 'agent' && lastMessage.ref) {
            this.selectedMessage = lastMessage.ref;
          }
        }
      })
    );
    this.unsubscribe = this.fs.getMessagesFromConversation(
      this.conversation.uid,
      this.conversation.ref,
      subject
    );
  }

  public async sendMessage() {
    if (!this.messageText || !this.fs.currentAppUser.uid || !this.conversation.ref) {
      console.error('sendMessage: No user uid');
      return;
    }
    const message: SupportMessage = {
      uid: this.fs.currentAppUser.uid,
      conversationRef: this.conversation.ref,
      message: this.messageText,
      createdAt: moment().utc().toISOString(),
      updatedAt: new Date().toISOString(),
      sender: 'user',
      reaction: SupportMessageReactions.support_no_reaction,
      email: this.fs.currentAppUser.email
    };
    await this.fs.createSupportMessage(
      this.conversation.uid,
      this.conversation.ref,
      message
    );
    await this.fs.updateConversation(
      this.conversation.uid,
      this.conversation.ref,
      {
        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        lastSender: 'user',
        lastMessage: this.messageText,
        readByAgent: false,
      }
    );
    this.messageText = '';
  }

  public async closeConversation() {
    if (!this.conversation.ref) {
      console.error('closeConversation: No user uid');
      return;
    }
    await this.fs.updateConversation(
      this.conversation.uid,
      this.conversation.ref,
      {
        status: 'resolved',
        updatedAt: new Date().toISOString()
      }
    );
    this.close(true);
  }

  public override ngOnDestroy(): void {
    this.editor.destroy();
  }
}
