import { Component, OnInit, } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { enumModalModes, FireFunctionsService, FireStoreService, MessengerService, ModalService, RMToasterService } from '@rm-services';
import { ButtonDirective, CardBodyComponent, CardComponent, ColComponent, ProgressComponent, RowComponent } from '@coreui/angular';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { StepsModule } from 'primeng/steps';
import { NgIf } from '@angular/common';
import { IconDirective } from '@coreui/icons-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cilChevronLeft, cilChevronRight, cilPeople, cilUserPlus } from '@coreui/icons';
import { AddUserComponent } from '../add-user/add-user.component';
import { AccountStatus, actions, appRoles, AvailableIntegration, BridgeType, CardStatus, dataTimeFormat, error_messages, GroupedDDL, Integrations, isEmail, SettingNames, Treezor } from '@roadmate/roadmate-common';
import { DragAndDropFileUploadComponent } from '@rm-modules/upload-file-area/drag-and-drop-file-upload.component';
import { getTableSettings } from '@rm-utils';
import { Ng2SmartTableComponent } from '@rm-modules/migration/tables/ng2-smart-table.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {GroupListComponent} from '@rm-modules/group-list/group-list.component';
import { modes } from '@rm-static';
import { ImageRenderPipe } from '@rm-modules/pipes';
import moment from 'moment';
enum Screens {
  choose_how_many = 'choose_how_many',
  form_add_one_user = 'form_add_one_user',
  choose_bulk_method = 'choose_bulk_method',
  upload_file = 'upload_file',
  validate_user_list = 'validate_user_list',
  choose_group_to_associate = 'choose_group_to_associate',
  done = 'done'
}

@Component({
  selector: 'rm-add-users-tunnel',
  templateUrl: './add-users-tunnel.component.html',
  styleUrls: ['./add-users-tunnel.component.scss'],
  standalone: true,
  imports: [
    CardComponent, CardBodyComponent, NgIf, IconDirective, TranslateModule, 
    CarouselModule,
    ButtonModule,
    TagModule, ImageRenderPipe,
    ColComponent, RowComponent, StepsModule,
    AddUserComponent, DragAndDropFileUploadComponent,
    Ng2SmartTableComponent, ProgressSpinnerModule,
    GroupListComponent, ButtonDirective
  ]
})
export class AddUsersTunnelComponent extends AbstractEntryComponent<void> implements OnInit {
  public currentIndex = 0;
  public allScreens = Screens;
  public currentScreen: Screens = Screens.choose_how_many;
  public screenStack: Screens[] = [Screens.choose_how_many];
  public loading = false;
  public file: File;
  public agentRef: string;
  public companyRef: string;
  public icons = {
    cilPeople,
    cilUserPlus,
    cilChevronLeft,
    cilChevronRight
  }
  public selectedGroup: GroupedDDL;
  public bulkMethods: '' | 'excel' | 'csv' = '';
  public integration: Integrations;
  public appUsersToAdd: Treezor.AppUser[] = [];
  public malFormedUsers: Treezor.AppUser[] = [];
  public settings: any;
  public headersTranslated: string[] = [];
  public currentFileName: string;
  public usersAddedWithSuccess = false;
  public integrations: AvailableIntegration[] = [];
  public currentIntegrationRef: string;
  public oneUserToAdd: Treezor.AppUser;
  public addMethod: 'file' | 'integration' | 'form' | '' = '';
  constructor(
    modalService: ModalService,
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    private translate: TranslateService,
    private msg: MessengerService
  ) {
    super(modalService)
  }

  ngOnInit(): void {
    this.agentRef = this.fs.currentAgent.ref ?? '';
    this.companyRef = this.fs.currentCompany.ref ?? '';
    // this.prepareTable();
    this.getIntegrations();
  }

  private async getIntegrations() {
    const intergrations = await this.fs.getCompanyAvailableIntegrations(
      this.agentRef,
      this.companyRef
    );
    this.integrations = intergrations.filter(el => el.categories?.find(el => el.value === BridgeType.syncUsersOnce));
  }

  private async prepareTable () {
    const list = await this.fs.getObjectsList();
    const appuserDef = JSON.parse(JSON.stringify(list['add-user-file']));
    this.settings = await getTableSettings(appuserDef, this.translate, true, {
      add: false,
      edit: false,
      delete: false
    });
    const headers: string[] = Object.keys(appuserDef).filter(key => appuserDef[key].lightDisplay).map(
      el => el
    ).sort(
      (a, b) => appuserDef[a].order - appuserDef[b].order
    );
    headers.forEach(header => {
      const column = this.settings.columns[header];
      this.headersTranslated.push(
        column?.title ?? header
      )
    });
  }

  public setGroup(group: GroupedDDL) {
    this.selectedGroup = group;
  }

  public previous() {
    switch (this.currentScreen) {
      case (Screens.choose_how_many): {
        return;
      }
      case (Screens.form_add_one_user): {
        this.currentScreen = Screens.choose_how_many;
        break;
      }
      case (Screens.choose_bulk_method): {
        this.currentScreen = Screens.choose_how_many;
        break;
      }
      case (Screens.upload_file): {
        this.currentScreen = Screens.choose_bulk_method;
        break;
      }
      case (Screens.validate_user_list): {
        this.currentScreen = Screens.upload_file;
        break;
      }
      case (Screens.choose_group_to_associate): {
        this.currentScreen = Screens.validate_user_list;
        break;
      }
      case (Screens.done): {
        return;
      }
    }
    if (this.currentIndex > 1) {
      // this.screenStack.pop();
      this.currentIndex --;
    }
  }

  public selectBulkMethod(method: '' | 'excel' | 'csv') {
    this.bulkMethods = method;
    this.addMethod = 'file';
    this.moveToScreen(Screens.upload_file);
  }

  public moveToScreen(screen: Screens) {
    this.screenStack.push(screen);
    this.currentScreen = screen;
    this.currentIndex ++;
    if (screen === Screens.form_add_one_user) {
      this.addMethod = 'form';
    }
  }

  public getGroups() {

  }

  public async useIntegration(integration: AvailableIntegration) {
    if (!integration?.ref) {
      return;
    }
    this.addMethod = 'integration';
    this.currentIntegrationRef = integration.ref;
    this.loading = true;
    this.moveToScreen(Screens.validate_user_list);
    try {
      const response = await this.ff.startAddingUsersFromIntegration(
        this.agentRef,
        this.companyRef,
        integration.ref,
        []
      );
      if (response.message === error_messages.OK && Array.isArray(response.result) && response.result.length && response.result.every(el => el.email)) {
        const appUsersFoundIntergration = response.result;
        this.appUsersToAdd = appUsersFoundIntergration.filter(el => true);
        this.moveToScreen(Screens.validate_user_list);
      } else {
        this.toast.showGenericError(response.message);
        return;
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public saveOneUser(user: Treezor.AppUser) {
    this.oneUserToAdd = user;
    this.moveToScreen(Screens.choose_group_to_associate);
    this.addMethod = 'form';
  }

  public next() {
    if (this.currentScreen === Screens.done || this.currentIndex === 0) {
      return;
    }
    if (this.currentIndex >= this.screenStack.length - 1) {
      return;
    }
    const nextScreen = this.screenStack[this.currentIndex + 1];
    this.currentScreen = nextScreen;
    this.currentIndex ++;
  }

  public async onFileUploaded(response: {
    downloadURL: string;
    fileName: string;
  } | null) {
    if (!response) {
      return;
    }
    this.loading = true;
    this.moveToScreen(Screens.validate_user_list);
    try {
      const res = await this.ff.startAddingUsers(this.agentRef, this.companyRef, response.fileName, [], true);
      if (res.message === error_messages.OK && res.result?.length) {
        this.appUsersToAdd = res.result.filter((el: Treezor.AppUser) => isEmail(el.email));
        this.malFormedUsers = res.result.filter((el: Treezor.AppUser) => el.email !== 'email' && !isEmail(el.email));
        this.currentFileName = response.fileName;
        await this.prepareTable();
      } else {
        this.toast.showGenericError(res.message);
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public displayMalFormedUsers() {
    if (!this.malFormedUsers.length) {
      return;
    }
    this.modalService.displayMessage(
      'malformed_users',
      'malformed_users_message',
      enumModalModes.primary,
      `<ul>${this.malFormedUsers.map(el => '<li>'+(el.email ? el.email : el.lastname)+'</li>')}</ul>`
    );
  }

  public async doCreateUsers() {
    if (!this.selectedGroup) {
      throw new Error('No group selected');
    }
    this.loading = true;
    this.moveToScreen(Screens.done);
    try {
      switch (this.addMethod) {
        case 'file': {
          const res = await this.ff.startAddingUsers(this.agentRef, this.companyRef, this.currentFileName, [this.selectedGroup], false);
          if (!res.error) {
            this.usersAddedWithSuccess = true;
            setTimeout(() => {
              this.msg.parcel.next({
                action: actions.syncUsers,
                data: null
              })
              this.close();
            }, 3000)
          }
          break;
        }
        case 'integration': {
          const res = await this.ff.startAddingUsersFromIntegration(
            this.agentRef,
            this.companyRef,
            this.currentIntegrationRef,
            [this.selectedGroup],
            this.appUsersToAdd
          );
          if (!res.error) {
            this.usersAddedWithSuccess = true;
            setTimeout(() => {
              this.msg.parcel.next({
                action: actions.syncUsers,
                data: null
              })
              this.close();
            }, 3000)
          }
          break;
        }
        case 'form': {
          await this.addOneUser();
        }
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  private async addOneUser() {
    this.loading = true;
    try {
      this.oneUserToAdd.companyRef = this.companyRef;
      this.oneUserToAdd.agentRef = this.agentRef;
      this.oneUserToAdd.agentName = this.fs.currentAgent.name;
      this.oneUserToAdd.role = this.oneUserToAdd.isAdmin ? appRoles.admin : appRoles.employee;
      this.oneUserToAdd.userGroupIds = [this.selectedGroup];
      this.oneUserToAdd.createdAt = moment().format(dataTimeFormat);
      this.oneUserToAdd.email = this.oneUserToAdd.email.toLowerCase().trim();
      this.oneUserToAdd.firstname = this.capitalize(this.oneUserToAdd.firstname);
      this.oneUserToAdd.lastname = this.capitalize(this.oneUserToAdd.lastname);
      this.oneUserToAdd.cardStatus = CardStatus.none;
      this.oneUserToAdd.accountStatus = AccountStatus.user_invited;
      const resp = await this.ff.createAppUser(this.oneUserToAdd);
      if (!resp.error) {
        this.usersAddedWithSuccess = true;
      }
      const setting = await this.fs.getCompanyDefaultSetting(
        this.agentRef,
        this.companyRef,
        SettingNames.SEND_WELCOME_EMAIL
      );
      let sendEmail = true;
      if (setting) {
        sendEmail = !!setting.value;
      }
      const message = sendEmail ? 
      `${this.oneUserToAdd.title} ${this.oneUserToAdd.lastname} a bien été ajouté${this.oneUserToAdd.title === 'M' ? '' : 'e'} et va recevoir un email pour activer son compte'` :
      `${this.oneUserToAdd.title} ${this.oneUserToAdd.lastname} a bien été ajouté${this.oneUserToAdd.title === 'M' ? '' : 'e'} mais ne recevra pas d'email car le paramètre d'envoi de email est désactivé`;
      if (resp && !resp.error) {
        this.toast.showToast({
          title: 'Notification',
          message,
          isVisible: true,
          mode: modes.success,
          duration: 5000
        });
        this.msg.parcel.next({
          action: actions.syncUsers,
          data: null
        });
      } else {
        this.toast.showToast({
          title: 'Oops',
          message: `Une erreur empêche la création de l'utilisateur. Veuillez recommencer plus tard !`,
          isVisible: true,
          duration: 5000,
          mode: modes.warning
        });
      }
    } catch (e) {
      console.error('Oops. User has not been created', e);
      this.toast.showToast({
        title: 'Oops',
        message: `Un soucis empêche la création de l'utilisateur. Veuillez recommencer plus tard !`,
        isVisible: true,
        duration: 5000,
        mode: modes.warning
      });
    } finally {
      this.loading = false;
      this.close();
    }
  }
}
