import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppRoles, CardSituation, error_messages, ExpenseLine, ExpenseLineStatus, 
  ObjectDefList, RoadMateResponse, Treezor, UserSituation, RoadMateRefundRequest } from '@roadmate/roadmate-common';
import { FireStoreService, FireAuthService, FireFunctionsService, ModalService, RMToasterService } from '@rm-services';
import { editUserActions } from '@rm-static';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getTableSettings } from '@rm-utils';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Tabs2Module, ProgressComponent, TextColorDirective, ColComponent, RowComponent, ListGroupModule } from '@coreui/angular';
import { ReactiveFormsComponent } from '@rm-modules/reactive-forms/reactive-forms.component';
import { VarDirective } from '@rm-modules/pipes/var.directive';
import { UserRefundsAndTransactionsComponent } from '../user-refunds-transactions/user-refunds-transactions.component';
import { TransactionsViewerComponent } from '@rm-modules/transactions-viewer/transactions-viewer.component';
import { Ng2SmartTableComponent } from '@rm-modules/migration/tables/ng2-smart-table.component';
import { UserCardComponent, UserCardComponentFeatures } from '../user-card/user-card.component'
import {userAccessLevelActions, UserAccessLevelComponent} from '../user-access-level/user-access-level.component'
import { cilWarning } from '@coreui/icons';
import { IconDirective } from '@coreui/icons-angular';
import {UserGroupsAndBudgetsComponent} from '../user-groups-budget/user-groups-budget.component'

@Component({
  selector: 'rm-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, NgClass, NgIf, TextColorDirective, ReactiveFormsComponent,
    VarDirective, ProgressComponent, TextColorDirective, NgStyle,
    UserRefundsAndTransactionsComponent, TransactionsViewerComponent, Ng2SmartTableComponent, NgFor,
    Tabs2Module, UserCardComponent, UserAccessLevelComponent, ColComponent, RowComponent, ListGroupModule,
    IconDirective, UserGroupsAndBudgetsComponent
  ]
})
export class EditUserComponent extends AbstractEntryComponent<{
  action: editUserActions,
  cardId?: number | string
} | undefined> implements OnInit {
  public targetObject: Treezor.AppUser;
  public objectDef: any;
  public isReadOnly = false;
  public savingLoader = false
  public currentTab = -1;
  
  
  public cards: CardSituation[];
  public card: CardSituation;
  public hasNoCard: boolean = true;
  public loadingCards = false;
  public statusCode = Treezor.Card.StatusCode;
  
  
  public loading = false;
  public isSuperAdmin = false;
  public isAccountManager = false;
  public treezorUser: Treezor.User.Definition | null;
  public treezorUserDef: any;
  public objectDefName: string;
  public userACL: AppRoles;
  private companyRef: string;
  private agentRef: string;
  public isSelf = false;
  public expenseLines: ExpenseLine[];
  public isInvitedButNotSubscribed = false;
  public isEmployee = false;
  public isAdminOrAccountManager = false;
  public userSituationRequest: RoadMateResponse<UserSituation>;
  override set data(value: any) {
    if (!value || !value.objectDef || !value.targetObject || !value.companyRef || !value.agentRef) {
      throw error_messages.PLEASE_SELECT_ONE_COMPANY;
      return;
    } else {
      this.agentRef = value.agentRef;
      this.companyRef = value.companyRef;
      this.objectDef = value.objectDef;
      this.objectDefName = value.objectDefName ?? '';
      this.targetObject = value.targetObject;
      this.suspended = !!this.targetObject.suspended;
      this.targetObject.userGroupIds = this.targetObject.userGroupIds ?? [];
      
      
      this.isReadOnly = !!value.isReadOnly;
    }
  }
  public adminItem = true;
  public cardTypeItem = true;
  public cardStatusItem = true;
  public cardLimitItem = true;
  public loadingUser = false;
  public suspended = false;
  
  public requests: RoadMateRefundRequest[];
  public requestsSettings: any;
  
  public icons = {
    cilWarning
  }
  constructor(
    private fs: FireStoreService,
    private fa: FireAuthService,
    private ff: FireFunctionsService,
    private ref: ChangeDetectorRef,
    private toast: RMToasterService,
    private translate: TranslateService,
    modalService: ModalService,
    // private chg: ChangeDetectorRef
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.getUserCardDetails();
    
    this.getDefinitions();
    this.isSuperAdmin = !!this.fa.userRoles.superadmin;
    this.isAdminOrAccountManager = !!this.fa.userRoles.admin || !!this.fa.userRoles.accountmanager;
    this.isAccountManager = !!this.fa.userRoles.accountmanager;
    this.isSelf = this.fa.user.uid === this.targetObject.uid;
    this.isEmployee = !!this.targetObject.userId;
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    setTimeout(() => {
    this.currentTab = 0;
    }, 500);
  }

  public updateUserACL(actions: userAccessLevelActions) {
    switch (actions) {
      case userAccessLevelActions.MAKE_ADMIN:
        this.close({
          action: editUserActions.makeAdmin
        });
        break;
      case userAccessLevelActions.REMOVE_ADMIN:
        this.close({
          action: editUserActions.revokeAdmin
        });
        break;
      case userAccessLevelActions.MAKE_ACCOUNT_MANAGER:
        this.close({
          action: editUserActions.makeAccountManager
        });
        break;
      case userAccessLevelActions.REMOVE_ACCOUNT_MANAGER:
        this.close({
          action: editUserActions.revokeAccountManager
        });
        break;
      case userAccessLevelActions.SUSPEND:
        this.close({
          action: editUserActions.suspendUser
        });
        break;
      case userAccessLevelActions.REACTIVATE:
        this.close({
          action: editUserActions.rehabilitateUser
        });
        break;
      case userAccessLevelActions.DELETE:
        this.close({
          action: editUserActions.deleteUser
        });
        break;
    }
  }

  public updateUserCard(actions: UserCardComponentFeatures) {
    switch (actions) {
      case UserCardComponentFeatures.DELETE_USER:
        this.deleteCard();
        break;
      case UserCardComponentFeatures.INVITE_USER:
        this.sendNewInvitation();
        break;
      case UserCardComponentFeatures.ORDER_NEW_CARD:
        this.orderNewCard();
        break;
      case UserCardComponentFeatures.LOCK_CARD:
        this.updateCartStatus(undefined, Treezor.Card.StatusCode.LOCK, `${this.card.cardId}`);
        break;
      case UserCardComponentFeatures.UNLOCK_CARD:
        this.updateCartStatus(undefined, Treezor.Card.StatusCode.UNLOCK, `${this.card.cardId}`);
        break;
      case UserCardComponentFeatures.DESTROY_CARD:
        this.deleteCard(true);
        break;
    }
  }



  public deleteCard(destroy = true) {
    const card = this.cards.find(el => !el.isRevoked);
    if (!card) {
      this.toast.showGenericError('Card not found');
      return;
    }
    this.close({
      action: destroy ? editUserActions.destroyCard : editUserActions.throwCard,
      cardId: card.cardId
    });
  }

  public orderNewCard() {
    this.close({
      action: editUserActions.createCard,
    });
  }



  private async getDefinitions() {
    const list = await this.fs.getObjectsList();
    this.treezorUserDef = list[ObjectDefList.treezorUser];
  }

  public async createTreezorUser() {
    this.loading = true;
    try {
      const response = await this.ff.adminUpdateTreezorUser(this.targetObject);
      if (!response.error && response.message === error_messages.OK) {
        this.toast.showSuccessToast('Done!');
        return;
      }
      this.toast.showGenericError();
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public async getTreezorUser() {
    if (!this.targetObject.uid) {
      return;
    }
    this.loading = true;
    try {
      const response = await this.ff.getTreezorUser(
        this.targetObject.agentRef,
        this.targetObject.companyRef,
        this.targetObject.uid
      );
      if (response.error || response.message !== error_messages.OK) {
        this.toast.showGenericError(response.message);
      } else {
        const treezorUser = response.result;
        if (treezorUser) {
          this.treezorUser = treezorUser;
        }
      }
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError();
    } finally {
      this.loading = false;
    }
  }

  public async sendObjectToSender(objectUpdated: Treezor.AppUser) {
    this.loading = true;
    try {
      if (this.objectDefName === ObjectDefList.appusers) {
        const success = await this.fs.updateAppUser(objectUpdated);
        if (success) {
          this.targetObject = {...this.targetObject, ...objectUpdated};
          this.toast.showSuccessToast('Done!');
          this.close(undefined);
          return;
        }
        this.toast.showGenericError();
      } else if (this.objectDefName === ObjectDefList.treezorUser) {
        this.updateTreezorUser(objectUpdated)
      }
    } catch (e) {
      this.toast.showGenericError();
    } finally {
      this.loading = false;
    }
  }

  public setTab(index: number) {
    // console.log('index', index);
    this.currentTab = index;
    if (index === 4 && !this.treezorUser) {
      this.getTreezorUser();
    }
    if (index === 6 && !this.requests) {
      this.getRequests();
    }
  }

  private async getRequests() {
    this.loading = true;
    try {
      const {email} = this.targetObject;
      if (!email) {
        this.toast.showGenericError('Impossible de charger les demandes de remboursement car email est indéfini.');
        return;
      }
      const requests = await this.fs.getRefundRequestsForUser(email);
      if (!requests || !requests.length) {
        this.toast.showWarningToast('Aucune demande de remboursement trouvée pour cet utilisateur.');
        return;
      }
      this.requests = requests.sort((a, b) => { return a.createdAt.localeCompare(b.createdAt) });
      const list = await this.fs.getObjectsList();
      const req = JSON.parse(JSON.stringify(list[ObjectDefList.refundRequests]));
      this.requestsSettings = await getTableSettings(req, this.translate);
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public convertCardToPhysical(event: any) {
    event.stopPropagation();
    this.close({
      action: editUserActions.convertPhysical
    });
  }

  private async getUserCardDetails () {
    if (!this.targetObject.email) {
      return;
    }
    this.loadingCards = true;
    try {
      this.userSituationRequest = await this.ff.adminGetUserStatus(
        this.agentRef,
        this.companyRef,
        this.targetObject.email
      );
      if (!this.userSituationRequest || this.userSituationRequest.error) {
        if (this.userSituationRequest.message === error_messages.NOT_FOUND) {
          await this.toast.showTranslatedWarning('this_is_not_a_beneficiary');
          return;
        } else if (
          this.userSituationRequest.message === error_messages.NOT_SUBSCRIBED_YET
        ) {
          this.isInvitedButNotSubscribed = true;
          await this.toast.showTranslatedWarning('this_user_not_subscribed_yet');
          return;
        } else if (this.userSituationRequest.message === error_messages.UNAUTHORIZED) {
          await this.toast.showTranslatedWarning('UNAUTHORIZED');
        }
        return;
      }
      if (this.userSituationRequest?.result) {
        this.cards = this.userSituationRequest.result.cards ?? [];
        if (this.cards.length) {
          this.card = this.cards[0];
        }
        const expenseLines = this.userSituationRequest.result.expenseLines ?? [];
        this.expenseLines = expenseLines
        .filter(ex =>
          ex.status === ExpenseLineStatus.Available ||
          ex.status === ExpenseLineStatus.Active ||
          ex.status === ExpenseLineStatus.Reliquat || 
          ex.status === ExpenseLineStatus.Suspended
        );
        this.userACL =  this.userSituationRequest.result.acl;
        this.hasNoCard = !(this.cards?.length > 0);
        this.ref.detectChanges();
      } else {
        this.toast.showGenericError('Impossible de charger les informations de cet utilisateur');
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loadingCards = false;
    }
  }

  public sendNewInvitation() {
    this.close({
      action: editUserActions.sendInviteToEmployee
    });
  }

  public makeAdmin(event: any, tomakeAdmin: boolean) {
    event.stopPropagation();
    this.close({
        action: tomakeAdmin ? editUserActions.makeAdmin : editUserActions.revokeAdmin
    });
  }

  public makeAccountManager(event: any, tomakeAccountManager: boolean) {
    event.stopPropagation();
    this.close({
        action: tomakeAccountManager ? editUserActions.makeAccountManager : editUserActions.revokeAccountManager
    });
  }

  public makeEmployee(event: any) {
    event.stopPropagation();
    this.close({
        action: editUserActions.makeEmployee
    });
  }

  public updateCartStatus(event: any, status: Treezor.Card.StatusCode, cardId: string) {
    event.stopPropagation();
    switch (status) {
      case Treezor.Card.StatusCode.LOCK:
        this.close({
          action: editUserActions.lockCard,
          cardId
        });
        break;
      case Treezor.Card.StatusCode.UNLOCK:
        this.close({
          action: editUserActions.unlockCard,
          cardId
        });
        break;
      case Treezor.Card.StatusCode.STOLEN:
      case Treezor.Card.StatusCode.LOST:
        this.close({
          action: editUserActions.throwCard,
          cardId
        });
        break;
    }
  }

  public async syncTreezorUser() {
    this.loading = true;
    try {
      const response = await this.ff.adminSyncTreezorUser(this.targetObject.email);
      if (!response.error && response.message === error_messages.OK) {
        this.toast.showSuccessToast('Done!');
        this.treezorUser = null;
        this.getTreezorUser();
      } else {
        this.toast.showGenericError();
      }
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError();
    } finally {
      this.loading = false;
    }
  }

  private removeBusinessFields (user: any) {
    Object.keys(user).forEach(key => {
      const index = ['legalName', 'legalNameEmbossed', 'legalRegistrationNumber', 'legalTvaNumber',
      'legalRegistrationDate', 'entityType', 'incomeRange', 'legalForm', 'legalShareCapital',
      'legalSector', 'legalAnnualTurnOver', 'legalNetIncomeRange', 'legalNumberOfEmployeeRange',
      'sepaCreditorIdentifier', 'walletCount', 'payinCount', 'totalRows', 'activityOutsideEu',
      'economicSanctions', 'residentCountriesSanctions', 'involvedSanctions', 'informationStatus',
      'isFreezed', 'kycLevel', 'kycReview', 'kycReviewComment', 'modifiedDate', 'timezone', 'userGroupIds', 'userTag', 'userStatus'
    ].indexOf(key);
      if (index > -1) {
        delete user[key];
      }
    });
  }


  public async updateTreezorUser(user: Partial<Treezor.User.EmployeeProfile>) {
    this.loading = true;
    try {
      if (user.userTypeId === Treezor.User.UserType.person) {
        this.removeBusinessFields(user);
      }
      const response = await this.ff.adminUpdateTreezorUser(user, this.companyRef);
      if (!response || response.error || response.message !== error_messages.OK) {
        this.toast.showGenericError(response.message);
      } else {
        this.toast.showSuccessToast('saved_success');
      }
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError();
    } finally {
      this.loading = false;
    }
  }

  public suspendUser(event: any, suspend: boolean) {
    event.stopPropagation();
    this.close({
      action: suspend ? editUserActions.suspendUser : editUserActions.rehabilitateUser
    });
  }

  public deleteUser() {
    // event.stopPropagation();
    this.close({
      action: editUserActions.deleteUser
    });
  }

  public generateNewCard() {
    this.close({
      action: editUserActions.generateNewCard
    })
  }

  

  
}
