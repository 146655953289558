import { Component, OnInit } from '@angular/core';
import { Group } from '@roadmate/roadmate-common';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalService } from '@rm-services';


@Component({
  selector: 'rm-adduserstogroup',
  templateUrl: './adduserstogroup.component.html',
  styleUrls: ['./adduserstogroup.component.scss'],
  standalone: true,
  imports: [
    NgIf, TranslateModule
  ]
})
export class AddUsersToGroupComponent extends AbstractEntryComponent<any> implements OnInit {
  public groups: Group[];
  public selectedGroup: Group;
  public nbUsersSelected: number;
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.nbUsersSelected = value.nbUsersSelected;
  }
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.getGroups();
  }

  private async getGroups() {
    // this.groups = await this.fs.getGroups(this.auth.appUser.companyRef);
  }

  public selectGroup(group: Group) {
    this.selectedGroup = group;
  }

  public cancel() {
    this.close(null);
  }

  public validateSelection() {
    this.close({data: this.selectedGroup});
  }
}
