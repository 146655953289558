/// <reference types="@angular/localize" />
// import * as Sentry from "@sentry/angular";

import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
// import { environment } from "./environments/environment";

// if (environment.production) {
//   Sentry.init({
//     dsn: "https://6a56b1cf56fcffbcb4ea6016b549d5ac@o309185.ingest.us.sentry.io/4508557138264064",
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [/https:\/\/roadmate-mobility-prod--release(.+)\.web\.app\//,/^https:\/\/pilotage\.roadmate\.io\//],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));

