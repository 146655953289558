<div class="card">
  <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
    <div class="flex gap-2">
      
    </div>
  </div>
  <div *ngIf="files">
    <!-- <h5>{{'loading' | translate}}</h5> -->
    <div class="flex flex-wrap p-0 sm:p-5 gap-5">
      <div *ngFor="let file of files; let i = index"
        class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
        <div>
          <img role="presentation" alt="CSV file" src="/assets/svg/csv.svg" width="100" height="80"
            *ngIf="fileType === 'csv'" />
          <img role="presentation" alt="Excel file" src="/assets/svg/xlsx.svg" width="100" height="80"
            *ngIf="fileType === 'excel'" />
        </div>
        <span class="font-semibold">{{ file.name }}</span>
        <div></div>
        <p-badge value="Pending" severity="warning" />
      </div>
    </div>
  </div>
  <div *ngIf="uploadedFiles.length > 0">
    <h5>{{'upload_finished'|translate}}</h5>
    <div class="flex flex-wrap p-0 sm:p-5 gap-5">
      <div *ngFor="let file of uploadedFiles; let i = index"
        class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
        <div>
          <img role="presentation" alt="CSV file" src="/assets/svg/csv.svg" width="100" height="100"
            *ngIf="fileType === 'csv'" />
          <img role="presentation" alt="Excel file" src="/assets/svg/xlsx.svg" width="100" height="100"
            *ngIf="fileType === 'excel'" />
        </div>
        <span class="font-semibold">{{ file.name }}</span>
        <p-badge value="Completed" class="mt-3" severity="success" />
        <p-button icon="pi pi-times" [outlined]="true" [rounded]="true" severity="danger" />
      </div>
    </div>
  </div>
  <div class="flex align-items-center justify-content-center flex-column rm-clickable p-3" (click)="selectFileInput.click()">
    <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
    <p class="mt-4 mb-0">{{'drag_drop_file_here_to_upload'|translate}} <b>{{fileType}}</b>.</p>
    <p-progressBar [value]="totalSizePercent" [showValue]="false" styleClass="md:w-20rem h-1rem w-full md:ml-auto"
      *ngIf="totalSizePercent > 0"
      [ngClass]="{ 'exceeded-progress-bar': totalSizePercent > 100 }">
      <span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span>
    </p-progressBar>
  </div>
  <div class="flex align-items-center justify-content-center flex-column rm-clickable p-3">
    <button class="btn btn-link" type="button" (click)="downloadTemplate()">{{'download_model' | translate}}</button>
  </div>
</div>
<input #selectFileInput hidden="true" type="file" onclick="this.value=null" (change)="selectFile($any($event).target.files)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
<rm-upload-task
  *ngIf="file"
  [companyRef]="companyRef"
  [agentRef]="agentRef"
  [file]="file"
  [fileSavingWorkFlow]="workflow"
  [displayLoader]="false"
  (finishEmitter)="onFileUploaded($event)"
>
</rm-upload-task>