import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalService } from '@rm-services';

@Component({
    selector: 'rm-ask-question',
    templateUrl: './ask-question.component.html',
    standalone: true,
    imports: [
      NgIf, TranslateModule
    ]
    
})
export class AskQuestionComponent extends AbstractEntryComponent<any> {
    public question: string;
    public suffix: string = '';
    public title: string;
    public html: string;
    override set data(value: any) {
      if (!value) {
        return;
      }
      this.question = value.question ? value.question : '';
      this.title = value.title ?? 'confirmation';
      this.suffix = value.suffix ?? '';
      this.html = value.html ?? '';
    }
    constructor(
      modalService: ModalService,
  ) {
    super(modalService);
    }
}
