<div class="detached letter" id="letter">
  <div class="rm-column">
    <div class="rm-row center bold leave-space-up a4-pad">
      {{'attestation_honneur_fmd' | translate}}
    </div>
    <div class="rm-row leave-space-up a4-pad">
      <p>
        <span style="font-weight: bold;">{{'object' | translate}}</span> : {{attestationObject | translate}}
      </p>
    </div>
    <div class="leave-space-up a4-pad">
      <p [innerHTML]="htmlText"></p>
    </div>
    <div class="detached a4-pad">
      <p>
        {{'SIGNATURE' |  translate}}
      </p>
    </div>
    <div class="signature-zone" style="width: 100%; height: 100%; max-height: 450px; border: 2px grey dashed">
      <signature-pad 
        [options]="signaturePadOptions" 
        (drawStart)="drawStart()" 
        [canvasStylesObject]="styles" 
        (drawEnd)="drawEnd()"
        style="width: 100%; height: 100%; max-height: 450px"
      ></signature-pad>
    </div>
  </div>
</div>
<div class="row detached" *ngIf="!dataSignature">
  <c-row>
    <c-col sm="12" md="6">
      {{'please_sign_attestation_above' | translate}}
    </c-col>
  </c-row>
</div>
<div class="row detached">
  <div class="col-sm-12 col-lg-6">
    <button class="btn btn-primary" type="button" (click)="removeSignature()" [disabled]="loading || !dataSignature">
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      {{'remove_signature' | translate}}
    </button>
    <button class="btn btn-primary" style="margin-left: 10px;" type="button" (click)="saveAttestation()" [disabled]="loading || !dataSignature">
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      {{'save' | translate}}
    </button>
  </div>
</div>