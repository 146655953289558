import {Component, EventEmitter, Input, Output} from '@angular/core';
import { appRoles, error_messages, Setting } from '@roadmate/roadmate-common';
import { FireStoreService, ModalService } from '@rm-services';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'rm-update-setting',
  templateUrl: 'update-setting.component.html',
  standalone: true,
  imports: [
    NgIf, NgClass, TranslateModule, NgSwitch, NgSwitchCase, NgSwitchDefault, FormsModule, NgFor
  ]
})
export class UpdateSettingComponent extends AbstractEntryComponent<boolean> {
  @Input()
  standalone = true;
  @Input()
  public setting: Setting;
  @Input()
  public mode: 'self' | 'company' = 'self';
  @Input()
  public companyRef: string;
  @Input()
  public agentRef: string;
  public title: string;
  public btns: {label: string; action: boolean}[] = [];
  public iframeHeight = 200;
  public loading = false;
  @Output()
  saveSettingEmitter = new EventEmitter<boolean>();
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.setting = value.setting;
    this.btns = value.btns ? value.btns : [{label: 'ok', action: true}] ;
    if (value.mode) {
      this.mode = value.mode;
    }
    if (value.companyRef) {
      this.companyRef = value.companyRef;
    }
    if (value.agentRef) {
      this.agentRef = value.agentRef;
    }
  }
  constructor(
    private fs: FireStoreService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  public async updateSetting() {
    this.loading = true;
    try {
      let res = false;
      switch (this.fs.currentAppUser.role) {
        case appRoles.agent:
          if (this.mode === 'self') {
            res = await this.fs.updateAgentDefaultSettings(this.agentRef, this.setting);
          } else if (this.mode === 'company') {
            if (!this.agentRef || !this.companyRef) {
              throw error_messages.PLEASE_SELECT_ONE_COMPANY;
            }
            res = await this.fs.updateCompanyDefaultSettings(this.agentRef, this.companyRef, this.setting);
          }
          break;
        case appRoles.superadmin:
          if (this.mode === 'company') {
            if (!this.agentRef || !this.companyRef) {
              throw error_messages.PLEASE_SELECT_ONE_COMPANY;
            }
            res = await this.fs.updateCompanyDefaultSettings(this.agentRef, this.companyRef, this.setting);
          } else {
            res = await this.fs.updateDefaultSetting(this.setting);
          }
          break;
        case appRoles.admin:
        case appRoles.accountManager: {
          if (!this.agentRef || !this.companyRef) {
            throw error_messages.PLEASE_SELECT_ONE_COMPANY;
          }
          res = await this.fs.updateCompanyDefaultSettings(this.agentRef, this.companyRef, this.setting);
          break;
        }
      }
      this.saveSettingEmitter.emit(res);
      this.close(res);
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public done () {
    this.close(false);
    this.saveSettingEmitter.emit(false);
  }
}
