import {Component} from '@angular/core';
import { environment } from '../../../environments/environment';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ModalService } from '@rm-services';

export interface DialogData {
  question: string;
  title: string;
  btns: {label: string; action: any}[];
}

@Component({
  selector: 'rm-kyb-dialog',
  templateUrl: 'kyb-dialog.component.html',
  styleUrls: ['kyb-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, NgIf, NgFor, NgClass
  ]
})
export class KybDialogComponent extends AbstractEntryComponent<{
  choice: boolean
}> {
  public question: string;
  public title: string;
  public dynamicValue: string;
  public suffix: string;
  public btns: {label: string; action: boolean}[] = [];
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.question = value.question ? value.question : '';
    this.btns = value.btns ? value.btns : [{label: 'ok', action: true}] ;
    this.dynamicValue = value.dynamicValue ? value.dynamicValue : '';
    this.suffix = value.suffix ?? '';
  }
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }


  public action(btn: {label: string; action: boolean}): void {
    let url = '';
    switch (btn.label) {
      case 'download_esf':
        // tslint:disable-next-line:max-line-length
        url = environment.esfForm;
        window.open(url, '_system', 'location=yes');
        this.close({
          choice: false
        });
        break;
      case 'download_empty_w9':
        // tslint:disable-next-line:max-line-length
        url = environment.w9Form;
        window.open(url, '_system', 'location=yes');
        this.close({
          choice: false
        });
        break;
      case 'yes_kyb_complete':
        this.close(
          {
            choice: true
          }
        );
      break;
      case 'no_kyb_complete':
        this.close(
          {
            choice: false
          }
        );
        break;
      case 'yes':
          this.close(
            {
              choice: true
            }
          );
          break;
      default:
        this.close({
          choice: false
        });
    }
  }
}
