<h5 class="push-down detached">{{'add_group_page' | translate}}</h5>
<c-row>
  <c-col sm="12">
    <rm-reactive-forms
      *ngIf="groupDef && !loading"
      [objectDefinition]="groupDef"
      [objectToUpdate]="currentGroup"
      [setLoading]="loading"
      [fileStoringWorkflow]="workflows.assets"
      [displayOnly]="readonly"
      (saveEmitter)="save($event)"
    >
    </rm-reactive-forms>
  </c-col>
</c-row>
