<!-- <p-steps [model]="items" [activeIndex]="currentIndex"></p-steps> -->

<c-row class="h100">
  <c-col sm="1">
    <div class="container-center-v h100 rm-clickable" (click)="previous()" *ngIf="currentScreen !== allScreens.done && currentScreen !== allScreens.choose_how_many">
      <svg [cIcon]="icons.cilChevronLeft" size="lg" title="Previous"></svg>
    </div>
  </c-col>
  <c-col sm="10" class="h100">
    <div class="main-content">
      @switch (currentScreen) {
        @case(allScreens.choose_how_many) {
          <c-row class="h100">
            <c-col sm="12" md="6">
              <div class="container-center-left h100">
                <c-card class="main-card rm-clickable" (click)="currentScreen = allScreens.choose_bulk_method">
                  <c-card-body>
                    <c-row>
                      <c-col sm="6">
                        <div class="bold tile-text">
                          <div class="font-amount">{{'import'|translate}}</div>
                          <div class="font-label">
                            {{'list_of_users'|translate}}
                          </div>
                        </div>
                      </c-col>
                      <c-col sm="6">
                        <div class="right font-icon">
                          <svg [cIcon]="icons.cilPeople" size="5xl" title="utilisateurs" ></svg>
                        </div>
                      </c-col>
                    </c-row>
                  </c-card-body>
                </c-card>
              </div>
            </c-col>
            <c-col sm="12" md="6">
              <div  class="container-center-left h100">
                <c-card class="main-card  rm-clickable" (click)="moveToScreen(allScreens.form_add_one_user)">
                  <c-card-body>
                    <c-row>
                      <c-col sm="6">
                        <div class="bold tile-text">
                          <div class="font-amount">{{'add'|translate}}</div>
                          <div class="font-label">
                            {{'one_user'|translate}}
                          </div>
                        </div>
                      </c-col>
                      <c-col sm="6">
                        <div class="right font-icon">
                          <svg [cIcon]="icons.cilUserPlus" size="5xl" title="utilisateurs" ></svg>
                        </div>
                      </c-col>
                    </c-row>
                  </c-card-body>
                </c-card>
              </div>
            </c-col>
          </c-row>
        }
        @case (allScreens.form_add_one_user) {
          <c-row class="h100">
            <c-col sm="1" md="4"></c-col>
            <c-col sm="10" md="4">
              <rm-add-user [standalone]="false" (saveUserEmitter)="saveOneUser($event)"></rm-add-user>
            </c-col>
            <c-col sm="1" md="4"></c-col>
          </c-row>
        }
        @case (allScreens.choose_bulk_method) {
          <h2>
            {{'file'|translate}}
          </h2>
          <c-row class="bulk-method detached">
            <c-col sm="6" md="6">
              <div class="container-center-left h100">
                <c-card class="main-card rm-clickable" (click)="selectBulkMethod('csv')">
                  <c-card-body>
                    <c-row>
                      <c-col sm="2"></c-col>
                      <c-col sm="8">
                        <div class="rm-column  container-center-v">
                          <img class="file-extension" src="/assets/svg/csv.svg" alt="CSV logo">
                          <h2>
                            {{'csv_file'|translate}}
                          </h2>
                        </div>
                      </c-col>
                      <c-col sm="2"></c-col>
                    </c-row>
                  </c-card-body>
                </c-card>
              </div>
            </c-col>
            <c-col sm="6" md="6">
              <div  class="container-center-left h100">
                <c-card class="main-card  rm-clickable"  (click)="selectBulkMethod('excel')">
                  <c-card-body>
                    <c-row>
                      <c-col sm="2"></c-col>
                      <c-col sm="8">
                        <div class="rm-column container-center-v">
                          <img class="file-extension" src="assets/svg/xlsx.svg" alt="XLSX logo">
                          <h2>
                            {{'xlsx_file'|translate}}
                          </h2>
                        </div>
                      </c-col>
                      <c-col sm="2"></c-col>
                    </c-row>
                  </c-card-body>
                </c-card>
              </div>
            </c-col>
          </c-row>
          <h2 class="detached">
            {{'api_connexion'|translate}}
          </h2>
          <c-row>
            <c-col sm="6" md="3" *ngFor="let integ of integrations">
              <c-card class="main-card  rm-clickable" (click)="useIntegration(integ)">
                <c-card-body  class="container-center-v rm-column">
                  <div class="container-center-v">
                    <img style="width: auto; height: 100px" class="file-extension" [src]="$any(integ).logo | rm_image_render" alt="integration logo">
                  </div>
                  <div class="container-center-v">
                    <h2>
                      {{integ.name}}
                    </h2>
                  </div>
                </c-card-body>
              </c-card>
            </c-col>
          </c-row>
        }
        @case (allScreens.upload_file) {
          <c-row class="h100">
            <c-col sm="12">
              <div class="container-center-v h100">
                <rm-drag-and-drop-file-upload
                  style="width: 100%;" 
                  [fileType]="bulkMethods"
                  [agentRef]="agentRef"
                  [companyRef]="companyRef"
                  (fileUrlReady)="onFileUploaded($event)"
                ></rm-drag-and-drop-file-upload>
              </div>
            </c-col>
          </c-row>
        }
        @case (allScreens.validate_user_list) {
          <c-row class="h100" *ngIf="loading">
            <c-col sm="12">
              <div class="container-center-v h100">
                <c-card class="main-card  rm-clickable">
                  <c-card-body>
                    <c-row>
                      <c-col sm="12" class="center">
                        {{'we_are_now_processing_your_file'|translate}}
                      </c-col>
                      <c-col sm="12" class="center detached">
                        <p-progressSpinner ariaLabel="loading" />
                      </c-col>
                    </c-row>
                  </c-card-body>
                </c-card>
              </div>
            </c-col>
          </c-row>
          <c-row *ngIf="appUsersToAdd.length  && settings && !loading && headersTranslated?.length" class="push-down">
            <c-col sm="12">
              <h4>{{'last_glimpse_before_saving_users' | translate}}</h4>
              <p>
                {{'check_if_values_are_aligned_with_properties'|translate}}
              </p>
              <p>
                <small>
                  {{'note_about_adding_users_prerequisites'|translate}}
                </small>
              </p>
              <!-- <p *ngIf="malFormedUsers.length" class="rm-clickable" (click)="displayMalFormedUsers()">
                <strong>
                  **{{'some_users_are_malformed'|translate}}**
                </strong>
              </p> -->
            </c-col>
            <c-col sm="12">
              <ng2-smart-table
                [source]="appUsersToAdd"
                [settings]="settings"
                [fileHeaders]="headersTranslated"
                [settings]="settings"
                [showExporter]="false"
                [selectable]="false"
                [objectName]="'appusers'"
              >
              </ng2-smart-table>
            </c-col>
            <c-col sm="12">
              <button cButton color="primary" type="button" (click)="moveToScreen(allScreens.choose_group_to_associate)">
                {{'VALIDATE' | translate}}
              </button>
            </c-col>
          </c-row>
        }
        @case (allScreens.choose_group_to_associate) {
          <c-row>
            <c-col sm="12">
              <h2>{{'please_select_group' | translate}}</h2>
            </c-col>
            <c-col sm="12">
              <rm-group-list [standalone]="false" (selectGroupEmitter)="setGroup($event)"></rm-group-list>
            </c-col>
          </c-row>
          <c-row>
            <c-col sm="12">
              <button cButton color="primary" type="button" (click)="doCreateUsers()" [disabled]="!selectedGroup">
                {{'do_create_users' | translate}}
              </button>
            </c-col>
          </c-row>
        }
        @case (allScreens.done) {
          <c-row class="h100" *ngIf="loading">
            <c-col sm="12">
              <div class="container-center-v h100">
                <c-card class="main-card  rm-clickable">
                  <c-card-body>
                    <c-row>
                      <c-col sm="12" class="center">
                        {{'we_are_adding_new_users_from_file'|translate}}
                      </c-col>
                      <c-col sm="12" class="center detached">
                        <p-progressSpinner ariaLabel="loading" />
                      </c-col>
                    </c-row>
                  </c-card-body>
                </c-card>
              </div>
            </c-col>
          </c-row>
          <c-row class="h100" *ngIf="!loading">
            <c-col sm="12">
              <div class="container-center-v h100">
                <c-card class="main-card  rm-clickable" (click)="close()">
                  <c-card-body>
                    <c-row>
                      <c-col sm="12" class="center">
                        <span *ngIf="usersAddedWithSuccess">
                          {{'import_was_successfull'|translate}}
                        </span>
                        <span *ngIf="!usersAddedWithSuccess">
                          {{'user_import_was_failed'|translate}}
                        </span>
                      </c-col>
                      <c-col sm="12" class="center detached">
                        <i  *ngIf="usersAddedWithSuccess" class="pi pi-check border-2 border-circle p-5 text-8xl text-400 border-400" style="color:lightgreen"></i>
                        <i  *ngIf="!usersAddedWithSuccess" class="pi pi-exclamation-triangle border-2 border-circle p-5 text-8xl text-400 border-400" style="color:lightgreen"></i>
                      </c-col>
                    </c-row>
                  </c-card-body>
                </c-card>
              </div>
            </c-col>
          </c-row>
        }
      }
    </div>
  </c-col>
  <c-col sm="1">
    <div class="container-center-v h100 rm-clickable" (click)="next()"  *ngIf="currentScreen !== allScreens.done && currentScreen !== allScreens.choose_how_many">
      <svg [cIcon]="icons.cilChevronRight" size="lg" title=""></svg>
    </div>
  </c-col>
</c-row>
