import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalService } from '@rm-services';
import { entryComponentList } from '../entrycomponents.list';
import { Subscription } from 'rxjs';
import { ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, 
  ModalHeaderComponent, ModalTitleDirective, ProgressComponent, ThemeDirective } from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgComponentOutlet, NgIf } from '@angular/common';
import { EditKyxComponent } from '../edit-kyx/edit-kyx.component';
import { KybDialogComponent } from '../kyb-dialog/kyb-dialog.component';
import { AddGroupComponent } from '../add-group/add-group.component';
import { AddUserComponent } from '../add-user/add-user.component';
import { AddUsersInfoComponent } from '../add-users-info/add-users-info.component';
import { AddUsersToGroupComponent } from '../add-users-to-group/adduserstogroup.component';
import { UpdateObjectComponent } from '../edit-object.ts/edit-object.component';
import { ConfirmTransferComponent } from '../confirm-transfer/confirm-transfer.component';
import { ConfirmPayoutComponent } from '../confirm-payout/confirm-payout.component';
import { VerifyTokenComponent } from '../verify-toekn/verify-token.component';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { LogoutWarningComponent } from '../logout-warning/logout-warning.component';
import { AssignMidComponent } from '../assign-mid/assign-mid.component';
import { DisplayWhiteListTreeComponent } from '../display-whitelist/display-whitelist.component';
import { EditCompanyComponent } from '../edit-company/edit-company.component';
import { ConfirmRefundComponent } from '../confirm-refund/confirm-refund.component';
import { AskQuestionComponent } from '../ask-question/ask-question.component';
import { DisplayHtmlComponent } from '../display-html/display-html.component';
import { UpdateSettingComponent } from '../update-setting/update-setting.component';
import { ProcessRefundComponent } from '../process-refund/process-refund.component';
import { ImportDeleteUsersComponent } from '../import-delete-users/import-delete-users.component';
import { ConfirmUserDeletionComponent } from '../confirm-user-deletion/confirm-user-deletion.component';
import { AddNewAccountManagerComponent } from '../add-new-account-manager/add-new-am.component';
import { TransactionDetailsComponent } from '../transaction-details/transaction-details.component';
import { SelectOneChoiceComponent } from '../select-one-choice/select-one-choice.component';
import { DisplayEligibleMobilityComponent } from '../display-eligible-mobility/display-eligible-mobility.component';
import { ConversationComponent } from '../conversation/conversation.component';
import { EmployeeConversationComponent } from '../employee-conversation/employee-conversation.component';
import { ConfirmBudgetAdjustmentComponent } from '../confirm-budget-adjustment/confirm-budget-adjustment.component';
import { IkvComponent } from '../ikv/ikv.component';
import { DeleteUserDetailsComponent } from '../deleted-user-modal/deleted-user-modal.component';
import { DisplayMobilityPartnerComponent } from '../display-mobility-partner/display-mobility-partner.component';
import { cilCopy } from '@coreui/icons';
import { IconDirective } from '@coreui/icons-angular';
import { RMToasterService } from '@rm-services';
import {AddUsersTunnelComponent} from '../add-users-tunnel/add-users-tunnel.component';
import { GroupsComponent } from '../groups/groups.component';

@Component({
  selector: 'rm-modal',
  templateUrl: 'rm-modal.component.html',
  styleUrls: ['rm-modal.component.scss'],
  standalone: true,
  imports: [
    ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ButtonDirective, TranslateModule, NgIf,
    NgComponentOutlet, ProgressComponent, ButtonCloseDirective, ButtonDirective, ModalTitleDirective, ThemeDirective,
    IconDirective
  ]
})
export class RmModalComponent implements OnInit, OnDestroy {
  @Input() modalTitle: string;
  @Input() mode: 'warning' | 'success' | 'danger' | 'primary';
  @Input() actionlbl: string;
  @Input() componentName: entryComponentList | '';
  @Input() data: any;
  @Input() dismissFooter = false;
  @Input() headerSuffix = '';
  @Input() modalId: string;
  @Input() size: 'small' | 'regular' | 'large' = 'regular';
  @Output()
  public actionClickEmitter: EventEmitter<any> = new EventEmitter<any>();
  public id: string;
  private subs: Subscription[] = [];
  public dynamicComponent: any;
  modalSize: 'sm' | 'lg' | 'xl'  = 'lg';
  icons = {
    cilCopy
  }
  constructor(
    private modalService: ModalService,
    private toast: RMToasterService
  ) {
    
  }

  ngOnInit(): void {
    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
    // console.log('size received', this.size);
    this.getComponent(this.componentName as entryComponentList);
    if (this.size === 'large') {
      this.modalSize = 'xl';
    }
    // );
    // const viewContainerRef = this.adHost.viewContainerRef;
    // viewContainerRef.clear();
    // const componentRef = viewContainerRef.createComponent<any>(componentFactory);
  }

  public async copyValue(value: string): Promise<void> {
    await navigator.clipboard.writeText(value);
    this.toast.showSuccessToast('copied_to_clipboard');
  }

  private async getComponent(component: entryComponentList): Promise<void> {
    switch (component) {
      case entryComponentList.editKyx:
        // this.dynamicComponent = EditKyxComponent();
        this.dynamicComponent = EditKyxComponent;
        break;
      case entryComponentList.kybDialog:
        this.dynamicComponent = KybDialogComponent;
        break;
      case entryComponentList.addGroup:
        this.dynamicComponent = AddGroupComponent;
        break;
      case entryComponentList.viewUsersInGroup:
        this.dynamicComponent = GroupsComponent;
        break;
      case entryComponentList.addUser:
        this.dynamicComponent = AddUserComponent;
        break;
      case entryComponentList.addUserInfo:
        this.dynamicComponent = AddUsersInfoComponent;
        break;
      case entryComponentList.addUsersToGroup:
        this.dynamicComponent = AddUsersToGroupComponent;
        break;
      case entryComponentList.editObject:
        this.dynamicComponent = UpdateObjectComponent;
        break;
      case entryComponentList.confirmTransfer:
        this.dynamicComponent = ConfirmTransferComponent;
        break;
      case entryComponentList.confirmPayout:
        this.dynamicComponent = ConfirmPayoutComponent;
        break;
      case entryComponentList.verifyToken:
        this.dynamicComponent = VerifyTokenComponent;
        break;
      case entryComponentList.editUser:
        this.dynamicComponent = EditUserComponent;
        break;
      case entryComponentList.logoutWarning:
        this.dynamicComponent = LogoutWarningComponent;
        break;
      case entryComponentList.assignMid:
        this.dynamicComponent = AssignMidComponent;
        break;
      case entryComponentList.displayWhiteList:
        this.dynamicComponent = DisplayWhiteListTreeComponent;
        break;
      case entryComponentList.editCompany:
        this.dynamicComponent = EditCompanyComponent;
        break;
      case entryComponentList.confirmRefund:
        this.dynamicComponent = ConfirmRefundComponent;
        break;
      case entryComponentList.askQuestion:
        this.dynamicComponent = AskQuestionComponent;
        break;
      case entryComponentList.displayHTML:
        this.dynamicComponent = DisplayHtmlComponent;
        break;
      case entryComponentList.updateSetting:
        this.dynamicComponent = UpdateSettingComponent;
        break;
        this.dynamicComponent = UpdateSettingComponent;
        break;
      case entryComponentList.processRefund:
        this.dynamicComponent = ProcessRefundComponent;
        break;
      case entryComponentList.deleteUsers:
        this.dynamicComponent = ImportDeleteUsersComponent;
        break;
      case entryComponentList.confirmDeletion:
        this.dynamicComponent = ConfirmUserDeletionComponent;
        break;
      case entryComponentList.addAM:
        this.dynamicComponent = AddNewAccountManagerComponent;
        break;
      case entryComponentList.transactionDetails:
        this.dynamicComponent = TransactionDetailsComponent;
        break;
      case entryComponentList.selectChoice:
        this.dynamicComponent = SelectOneChoiceComponent;
        break;
      case entryComponentList.displayMobilityList:
        this.dynamicComponent = DisplayEligibleMobilityComponent;
        break;
      case entryComponentList.conversation:
        this.dynamicComponent = ConversationComponent;
        break;
      case entryComponentList.employeeConversation:
        this.dynamicComponent = EmployeeConversationComponent;
        break;
      case entryComponentList.confirmBudgetAdjustment:
        this.dynamicComponent = ConfirmBudgetAdjustmentComponent;
        break;
      case entryComponentList.ikvComponent:
        this.dynamicComponent = IkvComponent;
        break;
      case entryComponentList.refundsAndTransactions:
        this.dynamicComponent = DeleteUserDetailsComponent;
        break;
      case entryComponentList.displayMerchant:
        this.dynamicComponent = DisplayMobilityPartnerComponent;
        break;
      case entryComponentList.addUserTunnel:
        this.dynamicComponent = AddUsersTunnelComponent;
        break;
      default:
        break;
    }
    return;

  }

  public close(response: any): void {
    this.modalService.close(response, this.modalId);
  }

  public ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
