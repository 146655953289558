import { Component, Input, OnInit } from '@angular/core';
import { RMToast, RMToasterService } from '@rm-services';
import { modes } from '@rm-static';
import { ButtonCloseDirective, ToastBodyComponent, ToastComponent, ToastHeaderComponent } from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';

@Component({
  selector: 'rm-toaster',
  templateUrl: './rm-toaster.component.html',
  styleUrls: ['./rm-toaster.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, NgFor, ToastComponent, ToastHeaderComponent, 
    ToastBodyComponent, 
    ButtonCloseDirective,
  ]
})
export class RMToasterComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() mode: modes;
  public allModes = modes;
  public isVisible = false;
  public toasts: RMToast[] = [];
  constructor(private toast: RMToasterService) {
    this.toast.toaster.subscribe(
      newToast => {
        if (this.toasts.find(t => t.id === newToast.id && !t.isVisible)) {
          this.toasts = this.toasts.filter(
            t => t.id !== newToast.id
          );
          return;
        }
        this.toasts = [
          ...this.toasts,
          newToast
        ];
        setTimeout(() => {
          this.onVisibleChange(false, newToast.id ?? '');
        }, 5000)
      }
    )
  }

  ngOnInit(): void {
    if (!this.mode) {
      this.mode = modes.primary;
    }
  }

  public close(toast: RMToast) {
    this.toast.close(toast);
  }

  public onVisibleChange(onvisibilityChanged: boolean, id: string) {
    if (!onvisibilityChanged) {
      this.toasts = this.toasts.filter(el => el.id !== id);
    }
  }
}
