import { Component, Input, OnInit } from '@angular/core';
import { IconDirective } from '@coreui/icons-angular';
import { RMToasterService } from '@rm-services';
import { modes } from '@rm-static';
import { cilCopy } from '@coreui/icons';

@Component({
  selector: 'rm-copy-icon',
  template: `
    <div class="rm-clickable" style="display: inline"  (click)="copy()">
      <svg [cIcon]="icons.cilCopy" size="sm" title="copy"></svg>
    </div>
  `,
  standalone: true,
  imports: [
    IconDirective
  ]
})
export class CopyInconComponent implements OnInit {
  @Input() text: string;
  icons = {
    cilCopy
  }
  constructor(private toast: RMToasterService) { }

  ngOnInit(): void { }

  async copy() {
    await navigator.clipboard.writeText(this.text);
    this.toast.showToast({
      title: 'Ok',
      message: 'Valeur copiée',
      mode: modes.primary,
      duration: 3000,
      isVisible: true
    });
  }
}
